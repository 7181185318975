import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Card, Popover, Select, Spin, Tag, Tooltip, message } from 'antd';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';
import { v4 as uuidv4 } from 'uuid';
import { DollarOutlined, LoadingOutlined } from '@ant-design/icons';
import HelpText from 'components/common/HelpText';
import 'lib/renderer/DynamicFormStyle.css';
import outputLangList from 'pages/preset/output_lang_list';
import { AIChatModels } from 'pages/chat/chatModels';


const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 16,
    }}
    spin
  />
);


const DynamicFormRenderer = forwardRef(
  (
    {
      form,
      handleInputChange,
      onResult,
      transcriptionId,
      showNReponse = true,
      n_words = 0,
      subscribed = false,
      formId = null,
      showBottomToolbar=true,
      initialFormData={},
      submitBtnLabel="Submit"
    },
    ref,
  ) => {
    const [apiInProgress, setAPIInProgress] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [showGuide, setShowGuide] = useState(false);
    const [currentElement, setCurrentElement] = useState(null);
    const [wordsCount, setWordCount] = useState({});
    const [characterCount, setCharacterCount] = useState({});
    const [selectedResponse, setSelectedResponse] = useState(1);
    const [selectedLength, setSelectedLength] = useState(-1);
    const [selectedEngine, setSelectedEngine] = useState(AIChatModels[0].key);
    const [finalPrompt, setFinalPrompt] = useState(form.prompt || '');
    const [showFormExpanded, setShowFormExpanded] = useState(true);

    const [transcriptionUUID, setTranscriptionUUID] = useState(null);
    const [outputLanguage, setOutputLanguage] = useState('English');

    const [accountBalance, setAccountBalance] = useState({
        n_words: 0,
        t_minutes: 0,
        n_chars: 0,
        n_images: 0,
        only_n_words: 0,
        only_t_minutes: 0,
        only_n_chars: 0,
        only_n_images: 0,
        "GPT4:n_words": 0,
        subscribed: false
    });

  const [supportedLLM, setSupportedLLM] = useState([AIChatModels[0].key]);

  const [triggerAccountBalance, setTriggerAccountBalance] = useState(true);

  const standardModelNames = AIChatModels
  .filter(model => !model.premium && model.template)
  .map(model => ({ name: model.name, color: 'cyan' }));
  
  const premiumModelNames = AIChatModels
  .filter(model => model.premium && model.template)
  .map(model => ({ name: model.name, color: 'cyan' }));

  const shouldModelEnabled = (modelName) => {

    let balanceKey = "n_words";
    if(modelName !== "GPT-3.5" && modelName !== "Gemma" && modelName !== "Gemini-Pro") {
      balanceKey = "GPT4:n_words";
    }

    if(!supportedLLM.includes(modelName)) {
      return false;
    }
    if(accountBalance[balanceKey] < -9999999) {
      return true;
    }
    if(accountBalance[balanceKey] < 10) {
      return false;
    }
    return true;
}

const getTooltipForModelOpt = (modelName) => {
  let balanceKey = "n_words";
  if(modelName !== "GPT" && modelName !== "Bard" && modelName !== "DocChat") {
    balanceKey = "GPT4:n_words";
  }
  if(!supportedLLM.includes(modelName)) {
     return <>
        Please <Button className='unmixr-primary' onClick={() => {window.location.href='/plan?tab=plans'}}>upgrade</Button> your plan to use this model.
     </>
  }
  if(accountBalance[balanceKey] < 10) {
    return <>
        You do not have sufficient balance to use this model.
     </>
  }
}

  useEffect(() => {
    // Call API to create transcription
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/account-balance/';
    const abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {
      "other_credits": ["GPT4:n_words"],
      "llm": true
    }

    UseAPI(signal, url, "POST", payload)
    .then(function(data) {
        // handle data here
        if(data.code === 200) {
            setAccountBalance(data.balance);
            setSupportedLLM(data.llm);
        }
        else {
            message.error("Error connecting to server");
        }
    })
    .catch(function(err) {
        if (err.name === 'AbortError') {
            console.log('Fetch aborted');
        } else {
            //alert("Error");
            message.error("Error connecting to server");
        }
    });

    // Abort fetch when component unmounts
    return () => {
      abortController.abort();
    };
}, [triggerAccountBalance]);

    const handleLanguageChange = (value) => {
      setOutputLanguage(value);
    };

    useEffect(() => {
        if(transcriptionId) {
          setTranscriptionUUID(transcriptionId);
        }
    }, [transcriptionId]);

    const [promptList, setPromptList] = useState([
      {
        label: 'Title',
        value: 'generate:title',
      },
      {
        label: 'Ads script',
        value:  'generate:adsscript',
      },
      {
        label: 'Show Notes',
        value:  'generate:showNotes',
      },
      {
        label: 'Marketing email',
        value: 'generate:marketingemail',
      },
      {
        label: 'Blog Post',
        value: 'generate:BlogPost',
      },
      {
        label: 'LinkedIn Post',
        value: 'generate:linkedinpost',
      },
      {
        label: 'Facebook Post',
        value: 'generate:facebookpost',
      },
      {
        label: 'Custom Prompt',
        value: 'other',
      },
    ]);

    const fields = form.fields;

    const prompt = form.prompt;

    useImperativeHandle(ref, () => ({
      resubmitForm() {
        handleFormSubmit(
          null,
          finalPrompt,
          formData,
          selectedResponse,
          selectedLength,
          selectedEngine,
        );
      },
    }));

    const handleFormSubmit = (event, finalPrompt, formData, n, word_limit, selectedEngine) => {
      // n is the number of responses
      //console.log(event);
      //console.log(formData);
      const requestId = uuidv4();
      let payload = {
        context: 'template:' + (form.context || 'general'),
        action: formId,
        prompt: finalPrompt,
        params: formData,
        request_source: 'WA',
        request_id: requestId,
        n: parseInt(n),
        wl: parseInt(word_limit),
        fields: form.fields.map((field) => field.id),
        engine: selectedEngine,
        output_language: outputLanguage,
        transcription_id: transcriptionUUID
      };

      onResult('in-progress', [], null, null);
      setAPIInProgress(true);

      let url = api_config.BASE_BACKEND_ENDPOINT + 'api/ai-completion/';
      let abortController = new AbortController();
      const signal = abortController.signal;
      UseAPI(signal, url, 'POST', payload)
        .then(function (data) {
          setAPIInProgress(false);
          if (Array.isArray(data)) {
            onResult('success', data, null, null);
            setTriggerAccountBalance(!triggerAccountBalance);
          } else {
            onResult('failed', [], data.code, null);
          }
        })
        .catch(function (err) {
          setAPIInProgress(false);
          onResult('failed', [], null, err);
        });
    };

    useEffect(() => {
      const initData = {};
      fields.forEach((field) => {
        if (field.widget === 'select') {
          initData[field.id] = { key: field.apilabel, value: field.defaultSelected || '' };
        }
      });
      setFormData({ ...initialFormData, ...initData });
    }, []);

    const handleResponseChange = (value) => {
      setSelectedResponse(value);
    };

    const handleLengthChange = (value) => {
      setSelectedLength(value);
    };

    const handleEngineChange = (value) => {
      setSelectedEngine(value);
    };

    const handleOutputLanguageChange = (value) => {
      setOutputLaguage(value);
    };

    const toggleGuide = (elementId, show) => {
      setShowGuide(show);
      setCurrentElement(elementId);
    };

    const onChangeHandler = (event, fieldId, maxWord, maxLen) => {
      let targetValue = '';
      //console.log(event.target);
      const tagName = event.target.tagName.toUpperCase();
      if (tagName === 'INPUT') {
        targetValue = event.target.value;
      } else if (tagName === 'TEXTAREA') {
        targetValue = event.target.value;
      } else if (tagName === 'DIV') {
        targetValue = event.target.textContent;
      }

      const words = targetValue.trim().split(/\s+/);
      const currentWordCount = words.length === 1 && words[0] === '' ? 0 : words.length;

      const newFormData = { ...formData };

      const inputValue = targetValue;
      //console.log(currentWordCount, maxWord);
      if (maxWord > 0 && currentWordCount >= maxWord) {
        const trimmedString = inputValue.replace(/\s+$/, '');
        newFormData[fieldId] = {
          key: event.currentTarget.getAttribute('data-api-label'),
          value: trimmedString,
        };
      } else if (maxLen > 0 && inputValue.length > maxLen) {
        const truncatedValue = inputValue.slice(0, maxLen);
        newFormData[fieldId] = {
          key: event.currentTarget.getAttribute('data-api-label'),
          value: truncatedValue,
        };
      } else {
        newFormData[fieldId] = {
          key: event.currentTarget.getAttribute('data-api-label'),
          value: targetValue,
        };
      }

      setFormData(newFormData);

      // Set word count
      const newWordCount = { ...wordsCount };

      newWordCount[fieldId] = currentWordCount;
      setWordCount(newWordCount);

      // Set character count
      const newCharacterCount = { ...characterCount };
      const currentChrCount = newFormData[fieldId].value.length;

      newCharacterCount[fieldId] = currentChrCount;
      setCharacterCount(newCharacterCount);

      //console.log(newFormData);
      handleInputChange(event, newFormData);
    };

    function handleSelectChange(value, event, fieldId, apilabel) {
      //console.log('Event:', event.target.tagName);
      console.log('Selected value:', value);
      console.log('Field Id:', fieldId);

      const newFormData = { ...formData };
      newFormData[fieldId] = { key: apilabel, value: value };
      setFormData(newFormData);
      handleInputChange(event, newFormData);
    }

    const getCurrentChrCount = (fieldId) => {
      return characterCount[fieldId] || 0;
    };

    const getCurrentWordCount = (fieldId) => {
      return wordsCount[fieldId] || 0;
    };

    const getFieldValue = (fieldId) => {
      let fieldDataObject = formData[fieldId] || {};
      if (fieldDataObject) {
        return fieldDataObject.value || '';
      }
      return '';
    };

    const validateFormField = (field) => {
      let fieldId = field.id;
      let fieldDict = formData[fieldId] || {};
      let fieldValue = fieldDict.value || '';
      const words = fieldValue.trim().split(/\s+/);
      const currentWordCount = words.length === 1 && words[0] === '' ? 0 : words.length;
      const currentChrCount = fieldValue.length;
      if (fieldValue) {
        if (field.minLength > 0) {
          if (currentChrCount < field.minLength) {
            return false;
          }
        } else if (field.minWords > 0) {
          if (currentWordCount < field.minWords) {
            return false;
          }
        } else if (field.maxLength > 0) {
          if (currentChrCount > field.maxLength) {
            return false;
          }
        } else if (field.maxWords > 0) {
          if (currentWordCount > field.maxWords) {
            return false;
          }
        }
      } else {
        if (field.required) {
          return false;
        }
        return true;
      }
      return true;
    };

    const isFormSubmittable = () => {
      if (finalPrompt === null) {
        return false;
      }
      for (var i = 0; i < fields.length; i++) {
        if (!validateFormField(fields[i])) {
          return false;
        }
      }
      return true;
    };

    const frmExpandedClass = () => {
      if (showFormExpanded) {
        return '';
      }
      return 'd-none';
    };

    const expandButtonStyle = {
      display: 'inline-block',
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '14px',
      border: 'none',
      background: '#efefef',
      borderRadius: '10px',
    };

    return (
      <>
        <div className={`dynamic-form-container ${frmExpandedClass()}`}>
          {fields.map((field) => {
            const fieldRequired = field.required ? 'required-label' : '';
            if (field.widget === 'input') {
              let renderField = true;
              if(field.showWhen) {
                if(!field.showWhen(formData)) {
                  renderField = false;
                }
              }
              if(renderField) {
                return (
                  <div className="dynamic-form-group input-guide-container" key={field.id}>
                    <div
                      style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                    >
                      <label className={`dynamic-form-label ${fieldRequired}`}>
                        {field.label} {field.showHelp && <HelpText text={field.helpText} />}
                      </label>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {field.maxLength > 0 && (
                          <span>
                            {getCurrentChrCount(field.id)} / {field.maxLength} characters
                          </span>
                        )}
                        {field.maxWords > 0 && (
                          <span>
                            {getCurrentWordCount(field.id)} / {field.maxWords} words
                          </span>
                        )}
                      </div>
                    </div>
                    <input
                      placeholder={field.placeholder}
                      value={getFieldValue(field.id)}
                      className={`dynamic-form-input`}
                      onChange={(event) =>
                        onChangeHandler(event, field.id, field.maxWords, field.maxLength)
                      }
                      onFocus={() => toggleGuide(field.id, true)}
                      //onBlur={() => toggleGuide(field.id, false)}
                      id={field.id}
                      autoComplete={field.autocomplete || 'off'}
                      data-api-label={field.apilabel}
                      type="text"
                    />
                    {field.shortDesc && (
                      <p style={{ fontSize: '12px', padding: '2px', color: '#555' }}>
                        {field.shortDesc}
                      </p>
                    )}
                    {showGuide && currentElement === field.id && field.showGuide && (
                      <div className="dynamic-form-input-guide">
                        <svg
                          onClick={() => toggleGuide(field.id, false)}
                          style={{
                            position: 'absolute',
                            top: '3px',
                            right: '3px',
                            width: '22px',
                            heigh: '22px',
                            cursor: 'pointer',
                          }}
                          className="unmixr-ai-popover-close"
                          viewBox="0 0 32 32"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M7.004 23.087l7.08-7.081-7.07-7.071L8.929 7.02l7.067 7.069L23.084 7l1.912 1.913-7.089 7.093 7.075 7.077-1.912 1.913-7.074-7.073L8.917 25z"></path>
                        </svg>
                        <div dangerouslySetInnerHTML={{ __html: field.guideText }}></div>
                      </div>
                    )}
                  </div>
                );
              }
              else {
                return null;
              }
              
            } else if (field.widget === 'textarea') {
              let renderField = true;
              if(field.showWhen) {
                if(!field.showWhen(formData)) {
                  renderField = false;
                }
              }
              if(renderField) {
                return (
                  <div className="dynamic-form-group input-guide-container" key={field.id}>
                    <div
                      style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                    >
                      <label className={`dynamic-form-label ${fieldRequired}`}>
                        {field.label} {field.showHelp && <HelpText text={field.helpText} />}
                      </label>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {field.maxLength > 0 && (
                          <span>
                            {getCurrentChrCount(field.id)} / {field.maxLength} characters
                          </span>
                        )}
                        {field.maxWords > 0 && (
                          <span>
                            {getCurrentWordCount(field.id)} / {field.maxWords} words
                          </span>
                        )}
                      </div>
                    </div>
                    <textarea
                      placeholder={field.placeholder}
                      value={getFieldValue(field.id) || field.initial}
                      className={`dynamic-form-textarea`}
                      onChange={(event) =>
                        onChangeHandler(event, field.id, field.maxWords, field.maxLength)
                      }
                      style={{
                        height: field.height || 'auto',
                      }}
                      onFocus={() => toggleGuide(field.id, true)}
                      //onBlur={() => toggleGuide(field.id, false)}
                      id={field.id}
                      data-api-label={field.apilabel}
                      autoComplete={field.autocomplete || 'off'}
                    ></textarea>
                    {field.shortDesc && (
                      <p style={{ fontSize: '12px', padding: '2px', color: '#555' }}>
                        {field.shortDesc}
                      </p>
                    )}
                    {showGuide && currentElement === field.id && field.showGuide && (
                      <div className="dynamic-form-input-guide">
                        <svg
                          onClick={() => toggleGuide(field.id, false)}
                          style={{
                            position: 'absolute',
                            top: '3px',
                            right: '3px',
                            width: '22px',
                            heigh: '22px',
                            cursor: 'pointer',
                          }}
                          className="unmixr-ai-popover-close"
                          viewBox="0 0 32 32"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M7.004 23.087l7.08-7.081-7.07-7.071L8.929 7.02l7.067 7.069L23.084 7l1.912 1.913-7.089 7.093 7.075 7.077-1.912 1.913-7.074-7.073L8.917 25z"></path>
                        </svg>
                        <div dangerouslySetInnerHTML={{ __html: field.guideText }}></div>
                      </div>
                    )}
                  </div>
                );
              }
              else {
                return null;
              }
            } else if (field.widget === 'div') {
              return (
                <div className="dynamic-form-group input-guide-container" key={field.id}>
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                  >
                    <label className={`dynamic-form-label ${fieldRequired}`}>
                      {field.label} {field.showHelp && <HelpText text={field.helpText} />}
                    </label>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {field.maxLength > 0 && (
                        <span>
                          {getCurrentChrCount(field.id)} / {field.maxLength} characters
                        </span>
                      )}
                      {field.maxWords > 0 && (
                        <span>
                          {getCurrentWordCount(field.id)} / {field.maxWords} words
                        </span>
                      )}
                    </div>
                  </div>
                  <textarea
                    placeholder={field.placeholder}
                    className={`dynamic-form-textarea`}
                    value={getFieldValue(field.id) || field.initial}
                    onChange={(event) =>
                      onChangeHandler(event, field.id, field.maxWords, field.maxLength)
                    }
                    onFocus={(event) => {
                      toggleGuide(field.id, true);
                    }}
                    // onBlur={() => toggleGuide(field.id, false)}
                    id={field.id}
                    data-api-label={field.apilabel}
                    autoComplete={field.autocomplete || 'off'}
                  />
                  {field.shortDesc && (
                    <p style={{ fontSize: '12px', padding: '2px', color: '#555' }}>
                      {field.shortDesc}
                    </p>
                  )}
                  {false && showGuide && currentElement === field.id && field.showGuide && (
                    <div className="dynamic-form-input-guide">
                      <svg
                        onClick={() => toggleGuide(field.id, false)}
                        style={{
                          position: 'absolute',
                          top: '3px',
                          right: '3px',
                          width: '22px',
                          heigh: '22px',
                          cursor: 'pointer',
                        }}
                        className="unmixr-ai-popover-close"
                        viewBox="0 0 32 32"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M7.004 23.087l7.08-7.081-7.07-7.071L8.929 7.02l7.067 7.069L23.084 7l1.912 1.913-7.089 7.093 7.075 7.077-1.912 1.913-7.074-7.073L8.917 25z"></path>
                      </svg>
                      <div dangerouslySetInnerHTML={{ __html: field.guideText }}></div>
                    </div>
                  )}
                </div>
              );
            } else if (field.widget === 'select') {
              return (
                <div className="dynamic-form-group input-guide-container" key={field.id}>
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                  >
                    <label className={`dynamic-form-label ${fieldRequired}`}>
                      {field.label} {field.showHelp && <HelpText text={field.helpText} />}
                    </label>
                  </div>
                  <Select
                    placeholder={field.placeholder}
                    value={getFieldValue(field.id) || field.defaultSelected}
                    className={`dynamic-form-select`}
                    style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      {
                        //console.log(input);
                        //console.log(option);
                        const inputValue = input.toLowerCase();
                        const optionLabel = option.children.toLowerCase();
                        return optionLabel.startsWith(inputValue);
                        //return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    }
                    onChange={(value, event) =>
                      handleSelectChange(
                        value || field.defaultSelected,
                        event,
                        field.id,
                        field.apilabel,
                      )
                    }
                    id={field.id}
                    data-api-label={field.apilabel}
                    showSearch
                    autoComplete={field.autocomplete || 'off'}
                  >
                    {
                       field.id === "content-generator:prompt" && promptList.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))
                    }
                    {field.id !== "content-generator:prompt" && field.options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                  {field.shortDesc && (
                    <p style={{ fontSize: '12px', padding: '2px', color: '#555' }}>
                      {field.shortDesc}
                    </p>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
          <div className="dynamic-form-container">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              { showBottomToolbar && 
              <>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'end' }}>
                {
                  <Tooltip title=<div><div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', fontSize: '0.9rem'}}>GPT-3 words can be used with: 
                    {standardModelNames.map(model => (
                      <Tag style={{margin: '3px', border: 'none'}} color={model.color} key={model.name}>
                        {model.name}
                      </Tag>
                    ))}</div></div>>
                    GPT-3 Words: <span style={{fontWeight: '600', marginRight: '10px'}}>{accountBalance.n_words > -9999999?accountBalance.n_words:"Unlimited"}</span>
                  </Tooltip>
                }
                {
                  <Tooltip title=<div><div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', fontSize: '0.9rem'}}>Premium words can be used with: 
                    {premiumModelNames.map(model => (
                      <Tag style={{margin: '3px', border: 'none'}} color={model.color} key={model.name}>
                        {model.name}
                      </Tag>
                    ))}</div></div>>
                  Premium Words: <span style={{fontWeight: '600'}}>{accountBalance["GPT4:n_words"]}</span>
                  </Tooltip>
                }
              </div>
              <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'end',
                      alignItems: 'center',
                      marginLeft: '10px',
                      marginTop: '13px'
                    }}
                  >
                    {true && (
                    <Select
                      className="dynamic-form-select"
                      style={{ width: '160px' }}
                      value={outputLanguage}
                      onChange={handleLanguageChange}
                    >
                      {outputLangList.map((language) => (
                        <Option key={language} value={language}>
                          {language}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {true && (
                      <Select
                        className="dynamic-form-select"
                        style={{ width: '240px', marginLeft: '10px' }}
                        value={selectedEngine}
                        onChange={handleEngineChange}
                      >
                        {AIChatModels.filter(model => model.template).map((model) => (
                          <Option 
                            disabled={!shouldModelEnabled(model.key)} 
                            key={model.key}
                          >
                            <Tooltip title={getTooltipForModelOpt(model.key)}>
                              {model.name} {model.premium?' (Premium)':''}
                            </Tooltip>
                          </Option>
                        ))}
                      </Select>
                    )}
                    <Button
                        className='unmixr-primary3'
                        onClick={(event) => {
                          handleFormSubmit(
                            event,
                            finalPrompt,
                            formData,
                            selectedResponse,
                            selectedLength,
                            selectedEngine,
                          );
                        }}
                        disabled={apiInProgress || !isFormSubmittable() || !shouldModelEnabled(selectedEngine)}
                        style={{ width: '120px', marginLeft: '10px', height: 'auto' }}
                      >
                        {apiInProgress && (
                          <Spin
                            indicator={antIcon}
                            style={{
                              marginRight: '3px',
                              marginTop: '3px',
                            }}
                          />
                        )}{' '}
                        {submitBtnLabel}
                      </Button>
                  </div>
              </>
              }
            </div>
          </div>
      </>
    );
  },
);

export default DynamicFormRenderer;
