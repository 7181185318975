export const AIChatModels = [
    // { key: 'Auto', name: 'Auto (Default)', provider: 'UnmixrAI', context: 'auto', premium: false },
    { key: 'GPT-4o-mini', name: 'GPT-4o-mini', provider: 'OpenAI', context: '128K Context', premium: false, vision: true, function: true, translation: true, template: true },
    // { key: 'Image-Generator', name: 'Image Generator', provider: 'UnmixrAI', context: 'Context:Auto', premium: false, vision: false },
    { key: 'GPT-3.5', name: 'GPT-3.5', provider: 'OpenAI', context: '16K Context', premium: false, vision: false, function: true, template: false },
    { key: 'GPT-4', name: 'GPT-4', provider: 'OpenAI', context: '8K Context', premium: true, vision: true, function: true, template: false },
    { key: 'GPT-4-O', name: 'GPT-4o', provider: 'OpenAI', context: '128K Context', premium: true, vision: true, function: true, translation: true, template: true },
    { key: 'GPT-4-Turbo', name: 'GPT-4-Turbo', provider: 'OpenAI', context: '128K Context', premium: true, vision: true, function: true, translation: false, template: true },
    { key: 'Gemini-Pro', name: 'Gemini-Pro-1.0', provider: 'Google', context: '131K Context', premium: false, vision: false, function: true, translation: false, template: true },
    { key: 'Gemini-Flash-1.5', name: 'Gemini-Flash-1.5', provider: 'Google', context: '2.8M Context', premium: true, vision: true, function: true, translation: true, template: true },
    { key: 'Gemini-Pro-1.5', name: 'Gemini-Pro-1.5', provider: 'Google', context: '2.8M Context', premium: true, vision: true, function: true, translation: true, template: true },
    { key: 'Gemma', name: 'Gemma', provider: 'Google', context: '8K Context', premium: false, vision: false, function: false, translation: false },
    { key: 'Claude-2', name: 'Claude-2.1', provider: 'Anthropic', context: '200K Context', premium: true, vision: false, function: false },
    { key: 'Claude-3-Opus', name: 'Claude-3-Opus', provider: 'Anthropic', context: '200K Context', premium: true, vision: true, function: false, translation: true, template: true },
    { key: 'Claude-3-Sonnet', name: 'Claude-3-Sonnet', provider: 'Anthropic', context: '200K Context', premium: true, vision: true, function: false, translation: true, template: true },
    { key: 'Claude-3.5-Sonnet', name: 'Claude-3.5-Sonnet', provider: 'Anthropic', context: '200K Context', premium: true, vision: true, function: false, translation: true, template: true },
    { key: 'Claude-3-Haiku', name: 'Claude-3-Haiku', provider: 'Anthropic', context: '200K Context', premium: true, vision: true, function: false, translation: true, template: true },
    { key: 'LLaMA-2', name: 'LLaMA-2', provider: 'Facebook', context: '4K Context', premium: true, vision: false, function: false },
    { key: 'LLaMA-3.1', name: 'LLaMA-3.1', provider: 'Facebook', context: '128K Context', premium: true, vision: false, function: false, translation: true, template: true },
    { key: 'Cohere', name: 'Cohere', provider: 'Cohere', context: '4K Context', premium: true, vision: false, function: false },
    { key: 'CommandR', name: 'Command R', provider: 'Cohere', context: '128K Context', premium: true, vision: false, function: false },
    { key: 'CommandR+', name: 'Command R+', provider: 'Cohere', context: '128K Context', premium: true, vision: false, function: false, translation: true, template: true },
    // { key: 'Jamba-Instruct', name: 'Jamba-Instruct', provider: 'AI21 Labs', context: '8k Context', premium: true, vision: false },
    // { key: 'Jurassic-2-Mid', name: 'Jurassic-2-Mid', provider: 'AI21 Labs', context: '8k Context', premium: true, vision: false },
    // { key: 'Jurassic-2-Ultra', name: 'Jurassic-2-Ultra', provider: 'AI21 Labs', context: '8k Context', premium: true, vision: false },
    // { key: 'Titan', name: 'Titan (Express)', provider: 'AWS', context: '8k Context', premium: true, vision: false },
    { key: 'Phi-3-mini', name: 'Phi-3-Mini', provider: 'Azure', context: '128k Context', premium: true, vision: false, function: false },
    { key: 'Mistral', name: 'Mistral Large', provider: 'MistralAI', context: '32K Context', premium: true, vision: false, function: false },
    { key: 'Perplexity', name: 'Perplexity', provider: 'Perplexity', context: '4K Context', premium: true, vision: false, function: false },
  ];