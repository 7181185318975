import React, { useEffect, useState } from 'react';
import PageLayout from '../../layout/PageLayout';
import UseAPI from 'lib/api/UseAPI';
import api_config from "lib/api/common"
import TranscriptionHistory from './TranscriptionHistory';
import RecordingOption from './RecordingOption';
import { Select, Card, Badge } from 'antd';
import { Button } from 'antd';
const { Option } = Select;
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import { ChevronLeft, ChevronRight  } from 'react-feather';
import "./index.css"
import NewTranscription from './NewTranscription';
import BackButton from 'components/common/BackButton';
import { AudioOutlined } from '@ant-design/icons';
import MicIconLarge from 'icons/MicIconLarge';
import DubbingIcon from 'icons/DubbingIcon';
import { Bars } from 'react-loader-spinner';
import TranscribeIconCustom from 'icons/TranscribeIconCustom';



const columns = [
  {
    title: 'File name',
    dataIndex: 'original_file',
    key: 'original_file',
    className: 'trans-col1',
  },
  {
    title: 'Language',
    dataIndex: 'detected_language',
    key: 'detected_language',
    className: 'trans-col2',
  },
  {
    title: 'Duration',
    dataIndex: 'audio_duration',
    key: 'audio_duration',
    className: 'trans-col3',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    className: 'trans-col4',
  },
  {
    title: 'Created on',
    dataIndex: 'date_created',
    key: 'date_created',
    className: 'trans-col7',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions'
  }
];

const TranscriptionHome = () => {
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [enablePrevPage, setEnablePrevPage] = useState(false);

  const [maxRecursionCount, setMaxRecursionCount] = useState(10);

  const [enableNextPage, setEnableNextPage] = useState(false);
  const [apiInProgress, setAPIInProgress] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [audioOption, setAudioOption] = useState("1");
  const [transData, setTransData] = useState(null);
  const [accountBalance, setAccountBalance] = useState({
      n_words: 0,
      t_minutes: 0,
      n_chars: 0,
      n_images: 0,
      subscribed: false
  });
  const [triggerAccountBalance, setTriggerAccountBalance] = useState(true);

  let dubbingRefreshController = new AbortController();

  useEffect(() => {
    // Call API to create transcription
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/account-balance/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {}

    UseAPI(signal, url, "POST", payload)
    .then(function(data) {
        // handle data here
        if(data.code === 200) {
            setAccountBalance(data.balance);
        }
        else {
            message.error("Error connecting to server");
        }
    })
    .catch(function(err) {
        if (err.name === 'AbortError') {
            console.log('Fetch aborted');
        } else {
            //alert("Error");
            message.error("Error connecting to server");
        }
    });

    // Abort fetch when component unmounts
    return () => {
      abortController.abort();
    };
  }, [triggerAccountBalance]);

  const statusCheckCallback = () => {
    refreshItems();
  }

  const checkItemStatus = (itemUUIDs) => {
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/t/check_status/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let pendingItemExists = false;
    UseAPI(signal, url, "POST", {
        tuids: itemUUIDs
    }).then(function(d) {
      if(d.code === 200) {
        let pendingItems = [];
        let otherItems = [];
        for(let key in d.result) {
          if(d.result[key] === "Transcribing" || d.result[key] === "PENDING") {
            pendingItemExists = true;
            pendingItems.push(key);
          }
          else {
            otherItems.push(key);
          }
        }

        if(otherItems.length > 0) {
          // setData(d => {
          //     return d.map(item => {
          //         const isItemFound = otherItems.includes(item.uuid);
          //         return isItemFound ? { ...item, status: 'Transcribed' } : item;
          //     });
          // });
          refreshItems(currentPage);
        }
      }

    }).catch(function(err) {
      console.log(err);
    });
  }

  const refreshItems = (page=1, rec_count=0) => {
    if(apiInProgress) {
      return false;
    }

    if(dubbingRefreshController) {
      dubbingRefreshController.abort();
    }

    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/t/?fields=uuid,original_file,file,detected_language,audio_duration,status,date_created&page=' + page;
    dubbingRefreshController = new AbortController();
    const signal = dubbingRefreshController.signal;
    //setData([]);
    setAPIInProgress(true);
    if(rec_count > 0) {
      setIsRefresh(true);
    }
    else {
      setIsRefresh(false);
    }
    UseAPI(signal, url, "GET", {}).then(function(data) {
      //console.log(data.results);
      setAPIInProgress(false);
      setData(data.results);

      let pendingItemExists = false;
      let pendingItems = [];
      for(var i = 0; i < data.results.length; i++) {
        if(data.results[i].status === "Transcribing") {
            pendingItemExists = true;
            pendingItems.push(data.results[i].uuid);
        }
      }

      setCurrentPage(data.current_page);
      setTotalPage(data.total_page);

      if(!data.prev_page) {
        setEnablePrevPage(false);
      }
      else {
        setEnablePrevPage(true);
      }

      if(!data.next_page) {
        setEnableNextPage(false);
      }
      else {
        setEnableNextPage(true);
      }

      if(data.total_page === 0) {
        setEnablePrevPage(false);
        setEnableNextPage(false);
      }

      // if(pendingItemExists) {
      //   setTimeout(function() {          
      //     checkItemStatus(pendingItems, rec_count=0)
      //   }, 1000 * (rec_count + 1));
      // }

    }).catch(function(err) {
      setAPIInProgress(false);
      setData([]);
      setEnablePrevPage(false);
      setEnableNextPage(false);
    });
  }

  const radioOptions = [
    {
      key: "1",
      label: "Record Audio"
    },
    {
      key: "2",
      label: "Upload"
    }
  ]

  useEffect(() => {
    // Set up the interval
    const intervalId = setInterval(() => {
      //console.log("Running");
      let pendingItemExists = false;
      let pendingItems = [];
      for(var i = 0; i < data.length; i++) {
        //console.log(data[i].status);
        if(data[i].status === "Transcribing" || data[i].status === "PENDING") {
            pendingItemExists = true;
            pendingItems.push(data[i].uuid);
        }
      }

      if(pendingItemExists) {
        checkItemStatus(pendingItems)
      }

    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [data]);

  useEffect(() => {
    setHeaders([...columns]);
    refreshItems(1);
  }, []);

  const handlePrevPagClick = (event) => {
    let prevPage = currentPage - 1;
    if(prevPage < 0) {
        setEnablePrevPage(false);
    }
    setCurrentPage(prevPage);
    if(dubbingRefreshController) {
      dubbingRefreshController.abort();
    }
    refreshItems(prevPage);
  }

  const handleNextPagClick = (event) => {
    let nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    if(dubbingRefreshController) {
      dubbingRefreshController.abort();
    }
    refreshItems(nextPage);
  }

  return (
    <PageLayout title="Transcription">
      <div className='transcription-container'>
          <div className='trans-history-container'>
               <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{padding: '5px 30px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <h3 className="trans-history-heading" style={{color: '#4e4e4e', display: 'flex', alignItems: 'center'}}><TranscribeIconCustom width={'38px'} height={'38px'}/> <span style={{marginLeft: '3px'}}>My Transcriptions</span></h3>
                    <div className='minutes-balance' style={{fontSize: '1rem', fontWeight: '600', border: 'none', background: 'transparent'}}>
                      Transcription Minutes: {accountBalance.t_minutes<=-99999999?'Unlimited':accountBalance.t_minutes.toFixed(0)}
                    </div>
                </div>
                <div style={{marginTop: '0px', padding: '0px 20px 20px 20px'}}>
                    {
                       <NewTranscription availableMinutes={accountBalance.t_minutes<=-99999999?99999999:accountBalance.t_minutes} statusCheckCallback={statusCheckCallback}/>
                    }
                    
                    <div style={{marginTop: '30px', overflowX: 'auto'}}>
                      {
                        apiInProgress && !isRefresh && <Bars
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="bars-loading"
                        wrapperStyle={{marginTop: '80px', marginBottom: '80px', display: 'flex', justifyContent: 'center'}}
                        wrapperClass=""
                        visible={true}
                      />
                      }
                      {
                        !(apiInProgress && !isRefresh) && <>
                        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '10px', paddingLeft: '10px', paddingRight: '10px'}}>
                          <span>Page {currentPage} of {totalPage}</span>
                        </div>
                        <TranscriptionHistory columns={headers} data={data}/>
                        </>
                      }
                    </div>
                    {
                      !(apiInProgress && !isRefresh) && 
                      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px'}}>
                        <span>Page {currentPage} of {totalPage}</span>
                      </div>
                    }                    
                    <div className='ai-transcription-pagination'>
                      <Button disabled={!enablePrevPage} onClick={(event) => handlePrevPagClick(event)} className={`ai-transcription-pagination-action ${enablePrevPage ? '' : 'disabled'}`}>
                          <ChevronLeft/>
                        </Button>
                        <Button disabled={!enableNextPage} onClick={(event) => handleNextPagClick(event)} className={`ai-transcription-pagination-action ${enableNextPage ? '' : 'disabled'}`}>
                          <ChevronRight/>
                        </Button>
                    </div>
                </div>
               </div>
              
          </div>
      </div>
    </PageLayout>
  );
};

export default TranscriptionHome;
