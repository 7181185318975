import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Space, Tooltip, Checkbox, Badge, Tag, message, Modal, Popconfirm } from 'antd';
import AudioPlayer from 'pages/voiceover/AudioPlayer';
import TranscriptionDetailButton from './TranscriptiondetailButton';
import { TRANSCRIPTION_AUDIO_TOPIC_DICT, TRANSCRIPTION_LANGUAGE_DICT } from './TranscriptionData';
import { Delete, Trash } from 'react-feather';
import UseAPI from 'lib/api/UseAPI';
import api_config from "lib/api/common"


const TranscriptionHistory = ({ columns, data }) => {
  const [expandedTranscription, setExpandedTranscription] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [tableData, setTableData] = useState(data);

  const history = useNavigate();

  const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'audio.mp3';
    link.click();
  };

  useEffect(() => {
    setTableData(data);
  }, [data]); // Log whenever selectedRows changes

  const handleCheckboxChange = (record, e) => {
    e.stopPropagation();
    const checked = e.target.checked;
    if (checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, record]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row.uuid !== record.uuid)
      );
    }
  };

  const performDelete = (itemUUID) => {
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/t/' + itemUUID + "/delete/";
    let abortController = new AbortController();
    const signal = abortController.signal;
    UseAPI(signal, url, "DELETE", {}).then(function(d) {
        if(d.code === 200) {
          message.success("Item deleted successfully");
          setTableData(currentData => currentData.filter(item => item.uuid !== itemUUID));
        }
        else {
          message.error("Unable to delete the item");
        }
    }).catch(function(err) {
       message.error("Unable to delete the item");
    });
  }

  const renderAudioPlayer = (text, record) => (
    <Space>
      <AudioPlayer onClickHandler={(event) => { event.stopPropagation(); }} audio_url={text} />
      <Tooltip title={text}>
        <Button onClick={() => handleDownload(text)}>Download</Button>
      </Tooltip>
      <Tooltip title={"Click to view detail"}>
        <TranscriptionDetailButton url={"/t/" + record.uuid} />
      </Tooltip>
    </Space>
  );

  const renderLanguage = (text, record) => (
      <Space>
        <span>{text?TRANSCRIPTION_LANGUAGE_DICT[text] || text:'-'}</span>
      </Space>
  )

  const convertSecondsToTimeFormat = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    seconds = Math.floor(seconds % 60);
  
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');
  
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  }

  const renderDuration = (text, record) => (
    <Space>
      <span style={{fontSize: '0.9rem', color: '#555'}}>{text?convertSecondsToTimeFormat(text):'-'}</span>
    </Space>
)

  const renderStatus = (text, record) => (
    <Space>
      {text === "Transcribing" ? (
        <Badge
          count={text}
          style={{
            backgroundColor: 'grey',
            animation: 'transcribingAnimation 2s infinite'
          }}
        />
      ) : <></>}
      {text === "Transcribed" ? (
        <Badge count={text} style={{ backgroundColor: '#52c41a' }} />
      ) : <></>}
      {text === "Failed" ? (
        <Badge count={text} style={{ backgroundColor: 'red' }} />
      ) : <></>}
    </Space>
  );


  const renderSubtitles = (text, record) => (
    <Space>
      {record.subtitles_available.map((language, index) => (
        <Tag key={index} color="blue">
          {language}
        </Tag>
      ))}
    </Space>
  )
  
  const renderDubbing = (text, record) => (
    <Space>
      {record.dubbing_available.map((dubbedLang, index) => (
        <Tag key={index} color="blue">
          {dubbedLang}
        </Tag>
      ))}
    </Space>
  )

  const renderFileName = (text, record) => (
    <Space>
      <Tooltip title={text}>
      <span style={{ display: 'inline-block', maxWidth: '150px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text?text:record.uuid}</span>
      </Tooltip>
    </Space>
  )

  const customColumns = columns.map((column) => {
    if (column.dataIndex === 'checkbox') {
      return {
        title: 'Select',
        render: (text, record) => (
          <Checkbox onClick={(e) => {e.stopPropagation();}} onChange={(e) => handleCheckboxChange(record, e)} />
        ),
      };
    }
    if (column.dataIndex === 'actions') {
      return {
        title: 'Actions',
        render: (text, record) => (
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={(e) => {
              e.stopPropagation(); 
              performDelete(record.uuid);
            }}
            onCancel={(e) => {
              e.stopPropagation(); 
            }}
            okText="Yes"
            cancelText="No"
          >
            <span type='danger' style={{cursor: 'pointer'}} onClick={(e) => {
              e.stopPropagation();               
            }}><Trash /></span>
          </Popconfirm>
        ),
      };
    }
    if(column.dataIndex === 'original_file') {
      return {
        ...column,
        render: renderFileName,
      };
    }
    if (column.dataIndex === 'date_created') {
      return {
        ...column,
        render: (text) => {
          const date = new Date(text);
          const formattedDateTime = date.toLocaleString('en-US');
          return <span>{formattedDateTime}</span>;
        },
      };
    }
    if (column.dataIndex === 'audio_duration') {
      return {
        ...column,
        render: renderDuration,
      };
    }
    if (column.dataIndex === 'file') {
      return {
        ...column,
        render: renderAudioPlayer,
      };
    }
    if(column.dataIndex === 'detected_language') {
      return {
        ...column,
        render: renderLanguage,
      };
    }
    if(column.dataIndex === 'subtitles_available') {
      return {
        ...column,
        render: renderSubtitles,
      };
    }
    if(column.dataIndex === 'dubbing_available') {
      return {
        ...column,
        render: renderDubbing,
      };
    }
    if(column.dataIndex === 'status') {
      return {
        ...column,
        render: renderStatus,
      };
    }
    return column;
  });

  return (
    <div className='trns-history-wrapper'>
      <Table 
      columns={customColumns} 
      dataSource={tableData} 
      pagination={false} 
      onRow={(record) => ({
        onMouseEnter: () => {
          if(record.status === "Transcribing" || record.status === "Failed") {
            return false;
          }
          setHoveredRow(record.uuid);
        },
        onMouseLeave: () => {
          if(record.status === "Transcribing" || record.status === "Failed") {
            return false;
          }
          setHoveredRow(null);
        },
        onClick: (e) => {
          // console.log(e);
          if(record.status === "Transcribing" || record.status === "Failed") {
            return false;
          }
          history("/transcription/" + record.uuid);
        },
      })}
      rowKey="uuid"
      rowClassName={(record) => (record.uuid === hoveredRow ? 'hovered-row' : '')}
      />
    </div>
  );
};

export default TranscriptionHistory;
