export const TRANSCRIPTION_OUTPUT_LANGUAGES = [
    { code: "en", lang: "English" },
    { code: "zh", lang: "Chinese" },
    { code: "de", lang: "German" },
    { code: "es", lang: "Spanish" },
    { code: "ru", lang: "Russian" },
    { code: "ko", lang: "Korean" },
    { code: "fr", lang: "French" },
    { code: "ja", lang: "Japanese" },
    { code: "pt", lang: "Portuguese" },
    { code: "tr", lang: "Turkish" },
    { code: "pl", lang: "Polish" },
    { code: "ca", lang: "Catalan" },
    { code: "nl", lang: "Dutch" },
    { code: "ar", lang: "Arabic" },
    { code: "sv", lang: "Swedish" },
    { code: "it", lang: "Italian" },
    { code: "id", lang: "Indonesian" },
    { code: "hi", lang: "Hindi" },
    { code: "fi", lang: "Finnish" },
    { code: "vi", lang: "Vietnamese" },
    { code: "he", lang: "Hebrew" },
    { code: "uk", lang: "Ukrainian" },
    { code: "el", lang: "Greek" },
    { code: "ms", lang: "Malay" },
    { code: "cs", lang: "Czech" },
    { code: "ro", lang: "Romanian" },
    { code: "da", lang: "Danish" },
    { code: "hu", lang: "Hungarian" },
    { code: "ta", lang: "Tamil" },
    { code: "no", lang: "Norwegian" },
    { code: "th", lang: "Thai" },
    { code: "ur", lang: "Urdu" },
    { code: "hr", lang: "Croatian" },
    { code: "bg", lang: "Bulgarian" },
    { code: "lt", lang: "Lithuanian" },
    { code: "la", lang: "Latin" },
    { code: "mi", lang: "Maori" },
    { code: "ml", lang: "Malayalam" },
    { code: "cy", lang: "Welsh" },
    { code: "sk", lang: "Slovak" },
    { code: "te", lang: "Telugu" },
    { code: "fa", lang: "Persian" },
    { code: "lv", lang: "Latvian" },
    { code: "bn", lang: "Bangla" },
    { code: "sr", lang: "Serbian" },
    { code: "az", lang: "Azerbaijani" },
    { code: "sl", lang: "Slovenian" },
    { code: "kn", lang: "Kannada" },
    { code: "et", lang: "Estonian" },
    { code: "mk", lang: "Macedonian" },
    { code: "br", lang: "Breton" },
    { code: "eu", lang: "Basque" },
    { code: "is", lang: "Icelandic" },
    { code: "hy", lang: "Armenian" },
    { code: "ne", lang: "Nepali" },
    { code: "mn", lang: "Mongolian" },
    { code: "bs", lang: "Bosnian" },
    { code: "kk", lang: "Kazakh" },
    { code: "sq", lang: "Albanian" },
    { code: "sw", lang: "Swahili" },
    { code: "gl", lang: "Galician" },
    { code: "mr", lang: "Marathi" },
    { code: "pa", lang: "Punjabi" },
    { code: "si", lang: "Sinhala" },
    { code: "km", lang: "Khmer" },
    { code: "sn", lang: "Shona" },
    { code: "yo", lang: "Yoruba" },
    { code: "so", lang: "Somali" },
    { code: "af", lang: "Afrikaans" },
    { code: "oc", lang: "Occitan" },
    { code: "ka", lang: "Georgian" },
    { code: "be", lang: "Belarusian" },
    { code: "tg", lang: "Tajik" },
    { code: "sd", lang: "Sindhi" },
    { code: "gu", lang: "Gujarati" },
    { code: "am", lang: "Amharic" },
    { code: "yi", lang: "Yiddish" },
    { code: "lo", lang: "Lao" },
    { code: "uz", lang: "Uzbek" },
    { code: "fo", lang: "Faroese" },
    { code: "ht", lang: "Haitian Creole" },
    { code: "ps", lang: "Pashto" },
    { code: "tk", lang: "Turkmen" },
    { code: "nn", lang: "Norwegian Nynorsk" },
    { code: "mt", lang: "Maltese" },
    { code: "sa", lang: "Sanskrit" },
    { code: "lb", lang: "Luxembourgish" },
    { code: "my", lang: "Burmese" },
    { code: "bo", lang: "Tibetan" },
    { code: "tl", lang: "Tagalog" },
    { code: "mg", lang: "Malagasy" },
    { code: "as", lang: "Assamese" },
    { code: "tt", lang: "Tatar" },
    { code: "haw", lang: "Hawaiian" },
    { code: "ln", lang: "Lingala" },
    { code: "ha", lang: "Hausa" },
    { code: "ba", lang: "Bashkir" },
    { code: "jw", lang: "Javanese" },
    { code: "su", lang: "Sundanese" }
  ];  
  
  export const TRANSCRIPTION_LANGUAGE_DICT = {};
  TRANSCRIPTION_OUTPUT_LANGUAGES.forEach((language) => {
    TRANSCRIPTION_LANGUAGE_DICT[language.code] = language.lang;
  });
  
  export const TRANSCRIPTION_AUDIO_TOPIC = [
    {id: 'general', name: 'General'}, // Title, Ads Script, Blog Post, LinkedIn, Facebook, Summaries, Topics
    {id: 'meeting', name: 'Meeting'}, // Meeting Notes, Title, Summaries, Action Items, Insight, Next Steps, Topics
    {id: 'phonecall', name: 'Phone Call'}, // Title, Summarize, Call Record Analysis, Action Items, Topics
    {id: 'sales-meeting', name: 'Sales Meeting'},
    // {id: 'voicemail', name: 'Voice Mail'},
    {id: 'finance', name: 'Finance'}, //Title, Summarize, Action Items, Insight
    {id: 'medical', name: 'Medical'}, // Summarize, Insight
    {id: 'podcast', name: 'Podcast'}, // Summarize, Show Notes, Title, Blog Post, LinkedIn, Facebook, Ads Script, Topics, Pain Points
    // {id: 'news', name: 'News'},
    {id: 'productdemo', name: 'Product Demo'}, // Summarize, Title, Blog Post, LinkedIn, Facebook, Ads Script
    {id: 'techtutorial', name: 'Tech Tutorial'}, // Summarize, Title, Blog Post, LinkedIn, Facebook, Ads Script, Topics, FAQ, SEO Keywords   
    // {id: 'conversation', name: 'Conversation'},
    // {id: 'interview', name: 'Interview'}, // Summarize, Title, Blog Post, LinkedIn, Facebook, FAQ
    {id: 'lecture', name: 'Lecture'}, // Summarize, Title, Blog Post, LinkedIn, Facebook, FAQ
    {id: 'webinar', name: 'Webinar'}, // Summarize, Title, Blog Post, LinkedIn, Facebook, Show Notes, Next Steps, Action Items
    // {id: 'legal', name: 'Legal'},
    // {id: 'research', name: 'Research'},
    {id: 'movie-documentary', name: 'Movie/Documentary'}, // Summarize, Blog Post, LinkedIn, Facebook, FAQ
    {id: 'videoscript', name: 'Video Script'}, // Summarize, Title, Blog Post, LinkedIn, Facebook, FAQ, Action Items
    {id: 'other', name: 'Other'}, // Summarize, Title, Blog Post, LinkedIn, Facebook, FAQ
  ];

  export const TRANSCRIPTION_AUDIO_TOPIC_DICT = {};
  TRANSCRIPTION_AUDIO_TOPIC.forEach((topic) => {
    TRANSCRIPTION_AUDIO_TOPIC_DICT[topic.id] = topic.name;
  });