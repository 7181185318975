import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';


const TranscriptionDetailButton = ({url}) => {
  const history = useNavigate();

  const handleButtonClick = () => {
    history(url);
  };

  return (
    <Button type="primary" onClick={handleButtonClick}>
      Detail
    </Button> 
  );
};

export default TranscriptionDetailButton;
