import ContentTransformer from 'data/template/content/ContentTransformer';
import PromptGenerator from 'data/template/content/PromptGenerator';


const TemplateFormDefs = {
  'generate-ai-content': ContentTransformer,
  'generate-ai-content-prompt': PromptGenerator
};

const TemplateForms = {
  TemplateFormDefs,
};

export default TemplateForms;
