import React, { useState, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import "./AudioPlayer.css"
import PlayIconCustom from 'icons/PlayIconCustom';
import PauseIconCustom from 'icons/PauseIconCustom';
import { Spin } from 'antd';

const AudioPlayer = ({ audio_url, onClickHandler, size="2em", preload="none" }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const togglePlay = async (event) => {

    event.stopPropagation();
    
    const audioElement = audioRef.current;

    if (isPlaying) {
      audioElement.pause();
    } else {
      setIsLoading(true);
      if (!audioElement.src) {
        audioElement.src = audio_url;
      }
      await audioElement.play();
    }

    setIsPlaying(!isPlaying);
    setIsLoading(false);

    onClickHandler(event);
  };

  const handleLoadedMetadata = () => {
    setIsLoading(false);
  };

  const handleEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="audio-player">
      <span style={{cursor: 'pointer'}} onClick={(event) => {togglePlay(event)}}>
        {isLoading ? (
          <Spin style={{ color: '#36f936' }}/>
        ) : isPlaying ? (
          <PauseIconCustom width={size} height={size} color={'#36f936'} style={{ marginTop: '9px' }} />
        ) : (
          <PlayIconCustom width={size} height={size} color={'#36f936'} style={{ marginTop: '9px' }} />
        )}
      </span>
      <audio
        ref={audioRef}
        preload={preload}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={handleEnded}
        type="audio/mpeg"
      >
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
