import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Popover, Select, Spin } from 'antd';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';
import { v4 as uuidv4 } from 'uuid';
import { DollarOutlined, LoadingOutlined } from '@ant-design/icons';
import HelpText from 'components/common/HelpText';
import 'lib/renderer/DynamicFormStyle.css';
import outputLangList from 'pages/preset/output_lang_list';


const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 16,
    }}
    spin
  />
);


const DynamicFormRendererPrompt = forwardRef(
  (
    {
      form,
      initialFormData,
      handleInputChange,
      onResult,
      transcriptionId,
      showNReponse = true,
      n_words = 0,
      subscribed = false,
      formId = null,
      showBottomToolbar=true
    },
    ref,
  ) => {
    const [apiInProgress, setAPIInProgress] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [showGuide, setShowGuide] = useState(false);
    const [currentElement, setCurrentElement] = useState(null);
    const [wordsCount, setWordCount] = useState({});
    const [characterCount, setCharacterCount] = useState({});
    const [selectedResponse, setSelectedResponse] = useState(1);
    const [selectedLength, setSelectedLength] = useState(-1);
    const [selectedEngine, setSelectedEngine] = useState('gpt3.5');
    const [finalPrompt, setFinalPrompt] = useState(form.prompt || '');
    const [showFormExpanded, setShowFormExpanded] = useState(true);

    const [transcriptionUUID, setTranscriptionUUID] = useState(null);
    const [outputLanguage, setOutputLanguage] = useState('English');

    const handleLanguageChange = (value) => {
      setOutputLanguage(value);
    };

    useEffect(() => {
        if(transcriptionId) {
          setTranscriptionUUID(transcriptionId);
        }
    }, [transcriptionId]);

    const fields = form.fields;

    const prompt = form.prompt;

    useImperativeHandle(ref, () => ({
      resubmitForm() {
        handleFormSubmit(
          null,
          finalPrompt,
          formData,
          selectedResponse,
          selectedLength,
          selectedEngine,
        );
      },
    }));

    const handleFormSubmit = (event, finalPrompt, formData, n, word_limit, selectedEngine) => {
      // n is the number of responses
      //console.log(event);
      //console.log(formData);
      const requestId = uuidv4();
      let payload = {
        context: 'template:' + (form.context || 'general'),
        action: formId,
        prompt: finalPrompt,
        params: formData,
        request_source: 'WA',
        request_id: requestId,
        n: parseInt(n),
        wl: parseInt(word_limit),
        fields: form.fields.map((field) => field.id),
        engine: selectedEngine,
        output_language: outputLanguage,
        transcription_id: transcriptionUUID
      };

      onResult('in-progress', [], null, null);
      setAPIInProgress(true);

      let url = api_config.BASE_BACKEND_ENDPOINT + 'api/ai-completion/';
      let abortController = new AbortController();
      const signal = abortController.signal;
      UseAPI(signal, url, 'POST', payload)
        .then(function (data) {
          setAPIInProgress(false);
          if (Array.isArray(data)) {
            onResult('success', data, null, null);
          } else {
            onResult('failed', [], data.code, null);
          }
        })
        .catch(function (err) {
          setAPIInProgress(false);
          onResult('failed', [], null, err);
        });
    };

    useEffect(() => {
      const initData = {};
      fields.forEach((field) => {
        if (field.widget === 'select') {
          initData[field.id] = { key: field.apilabel, value: field.defaultSelected || '' };
        }
      });
      setFormData(initData);
    }, []);

    const handleResponseChange = (value) => {
      setSelectedResponse(value);
    };

    const handleLengthChange = (value) => {
      setSelectedLength(value);
    };

    const handleEngineChange = (value) => {
      setSelectedEngine(value);
    };

    const handleOutputLanguageChange = (value) => {
      setOutputLaguage(value);
    };

    const toggleGuide = (elementId, show) => {
      setShowGuide(show);
      setCurrentElement(elementId);
    };

    const onChangeHandler = (event, fieldId, maxWord, maxLen) => {
      let targetValue = '';
      //console.log(event.target);
      const tagName = event.target.tagName.toUpperCase();
      if (tagName === 'INPUT') {
        targetValue = event.target.value;
      } else if (tagName === 'TEXTAREA') {
        targetValue = event.target.value;
      } else if (tagName === 'DIV') {
        targetValue = event.target.textContent;
      }

      const words = targetValue.trim().split(/\s+/);
      const currentWordCount = words.length === 1 && words[0] === '' ? 0 : words.length;

      const newFormData = { ...formData };

      const inputValue = targetValue;
      //console.log(currentWordCount, maxWord);
      if (maxWord > 0 && currentWordCount >= maxWord) {
        const trimmedString = inputValue.replace(/\s+$/, '');
        newFormData[fieldId] = {
          key: event.currentTarget.getAttribute('data-api-label'),
          value: trimmedString,
        };
      } else if (maxLen > 0 && inputValue.length > maxLen) {
        const truncatedValue = inputValue.slice(0, maxLen);
        newFormData[fieldId] = {
          key: event.currentTarget.getAttribute('data-api-label'),
          value: truncatedValue,
        };
      } else {
        newFormData[fieldId] = {
          key: event.currentTarget.getAttribute('data-api-label'),
          value: targetValue,
        };
      }

      setFormData(newFormData);

      // Set word count
      const newWordCount = { ...wordsCount };

      newWordCount[fieldId] = currentWordCount;
      setWordCount(newWordCount);

      // Set character count
      const newCharacterCount = { ...characterCount };
      const currentChrCount = newFormData[fieldId].value.length;

      newCharacterCount[fieldId] = currentChrCount;
      setCharacterCount(newCharacterCount);

      //console.log(newFormData);
      handleInputChange(event, newFormData);
    };

    function handleSelectChange(value, event, fieldId, apilabel) {
      //console.log('Event:', event.target.tagName);
      console.log('Selected value:', value);
      console.log('Field Id:', fieldId);

      const newFormData = { ...formData };
      newFormData[fieldId] = { key: apilabel, value: value };
      setFormData(newFormData);
      handleInputChange(event, newFormData);
    }

    const getCurrentChrCount = (fieldId) => {
      return characterCount[fieldId] || 0;
    };

    const getCurrentWordCount = (fieldId) => {
      return wordsCount[fieldId] || 0;
    };

    const getFieldValue = (fieldId) => {
      let fieldDataObject = formData[fieldId] || {};
      if (fieldDataObject) {
        return fieldDataObject.value || '';
      }
      return '';
    };

    const validateFormField = (field) => {
      let fieldId = field.id;
      let fieldDict = formData[fieldId] || {};
      let fieldValue = fieldDict.value || '';
      const words = fieldValue.trim().split(/\s+/);
      const currentWordCount = words.length === 1 && words[0] === '' ? 0 : words.length;
      const currentChrCount = fieldValue.length;
      if (fieldValue) {
        if (field.minLength > 0) {
          if (currentChrCount < field.minLength) {
            return false;
          }
        } else if (field.minWords > 0) {
          if (currentWordCount < field.minWords) {
            return false;
          }
        } else if (field.maxLength > 0) {
          if (currentChrCount > field.maxLength) {
            return false;
          }
        } else if (field.maxWords > 0) {
          if (currentWordCount > field.maxWords) {
            return false;
          }
        }
      } else {
        if (field.required) {
          return false;
        }
        return true;
      }
      return true;
    };

    const isFormSubmittable = () => {
      if (finalPrompt === null) {
        return false;
      }
      for (var i = 0; i < fields.length; i++) {
        if (!validateFormField(fields[i])) {
          return false;
        }
      }
      return true;
    };

    const frmExpandedClass = () => {
      if (showFormExpanded) {
        return '';
      }
      return 'd-none';
    };

    const expandButtonStyle = {
      display: 'inline-block',
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '14px',
      border: 'none',
      background: '#efefef',
      borderRadius: '10px',
    };

    return (
      <>
        <div className="dynamic-form-container">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              { showBottomToolbar && 
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {false && showNReponse && (
                  <Select
                    className="dynamic-form-select"
                    style={{ width: 'calc(50% - 10px)', marginRight: '10px' }}
                    value={selectedResponse}
                    onChange={handleResponseChange}
                  >
                    <Option key={1} value={1}>
                      1 result
                    </Option>
                    <Option key={2} value={2}>
                      2 results
                    </Option>
                    <Option key={3} value={3}>
                      3 results
                    </Option>
                  </Select>
                )}
                
                {true && (
                  <Select
                    className="dynamic-form-select"
                    style={{ width: 'calc(50% - 10px)', flexGrow: '1' }}
                    value={outputLanguage}
                    onChange={handleLanguageChange}
                  >
                    {outputLangList.map((language) => (
                      <Option key={language} value={language}>
                        {language}
                      </Option>
                    ))}
                  </Select>
                )}
                {true && (
                  <Select
                    className="dynamic-form-select"
                    style={{ width: 'calc(50% - 10px)', flexGrow: '1' }}
                    value={selectedEngine}
                    onChange={handleEngineChange}
                  >
                    <Option key="gpt3.5">GPT 3.5 (Fastest)</Option>
                    <Option key="gpt4">GPT 4 (Most Advanced)</Option>
                  </Select>
                )}
              </div>
              }

              <div
                style={{
                  marginTop: showBottomToolbar?'15px':'0px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'end',
                }}
              >
                {n_words < 10 && n_words > -9999999 && (
                  <>
                  {
                    subscribed
                        ? <div style={{width: '100%', marginBottom: '10px'}}>You do not have sufficient word balance. To get more words please contact at support@chatscribe.pro</div>
                        : <div style={{marginRight: '10px'}}><Button onClick={() => {window.location.href='/plan?tab=plans'}} className="unmixr-primary">Upgrade</Button> to use this feature</div>
                  }
                  <Popover
                    content={
                      subscribed
                        ? 'You do not have sufficient word balance. To get more words please contact at support@chatscribe.pro'
                        : <div><span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {window.location.href='/plan?tab=plans'}} className="unmixr-primary">Upgrade</span> to use this feature</div>
                    }
                  >
                    <Button
                      style={{ height: 'auto', flexGrow: '1' }}
                      className={`dynamic-form-submit disabled unmixr-primary`}
                      disabled={true}
                    >
                      <DollarOutlined /> Submit
                    </Button>
                  </Popover>
                  </>
                )}
                {(n_words >= 10 || n_words <= -9999999) && (
                  <Button
                    type="primary"
                    onClick={(event) => {
                      handleFormSubmit(
                        event,
                        finalPrompt,
                        formData,
                        selectedResponse,
                        selectedLength,
                        selectedEngine,
                      );
                    }}
                    disabled={apiInProgress || !isFormSubmittable()}
                    style={{ width: 'calc(50% - 10px)', height: 'auto', flexGrow: '1' }}
                  >
                    {apiInProgress && (
                      <Spin
                        indicator={antIcon}
                        style={{
                          marginRight: '3px',
                          marginTop: '3px',
                        }}
                      />
                    )}{' '}
                    Generate
                  </Button>
                )}
              </div>
            </div>
          </div>
      </>
    );
  },
);

export default DynamicFormRendererPrompt;
