import TransformerIcon from 'icons/TransformerIcon';

export default {
  context: 'ai_content_generator', // Based on context, generate GPT instruction in backend.
  title: '',
  prompt: '',
  shortDesc: '',
  prompt_widget: '', // default input
  prompt_placeholder: ``,
  prompt_style: { minHeight: '200px' },
  hide_content_length: false,
  fields: [],
};
