import React from 'react';

const PlayIconCustom = ({ width, height, color }) => {
  return (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="play-circle"
      width={width}
      height={height}
      fill={color}
      aria-hidden="true"
    >
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
      <path d="M719.4 499.1l-296.1-215A15.9 15.9 0 00398 297v430c0 13.1 14.8 20.5 25.3 12.9l296.1-215a15.9 15.9 0 000-25.8zm-257.6 134V390.9L628.5 512 461.8 633.1z"></path>
    </svg>
  );
};

export default PlayIconCustom;
