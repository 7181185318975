import React, { useContext, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { CopyOutlined, DownloadOutlined, FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import PageLayout from '../../layout/PageLayout';
import TemplateForms from 'data/template/templateForm';
import DynamicFormRenderer from 'lib/renderer/DynamicFormRenderer';
import { StoreContext } from 'store';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';
import { Badge, Checkbox, Collapse, Dropdown, Menu, Space, Tabs, Tooltip } from 'antd';
const { TabPane } = Tabs;
import './TemplateForm.css';
import ReactMarkdown from 'react-markdown';
import ContentLoader from 'components/common/ContentLoader';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Button, Card, notification, message } from 'antd';
import { renderToString } from 'react-dom/server';
import { ChevronLeft, ChevronRight } from 'react-feather';
import TranslatorLangDict from './translator_lang_list';
import DynamicFormRendererPrompt from 'lib/renderer/DynamicFormRendererPrompt';
import DownloadDocxAPI from 'lib/api/DownloadDocxAPI';
import DocxIcon from 'icons/DocxIcon';
// import OneShotCreator from 'data/template/custom/OneShotCreator';

const { Panel } = Collapse;

const renderNWords = (text, n=30) => {
  if (!text) {
    return "";
  }
  
  // Split text into words
  const words = text.split(/\s+/);
  
  // Get the first 50 words and join them into a string
  const limitedWords = words.slice(0, n).join(' ');

  return limitedWords;
};


const GetForm = (templateId) => {
  // if(templateId === "one-shot-creator") {
  //     return OneShotCreator;
  // }
  const dynamicForm = TemplateForms.TemplateFormDefs[templateId] ?? null;
  // alert(dynamicForm);
  return dynamicForm;
};

const TemplateForm = ({templateid, transcriptionId, savedPrompts, allPrompts}) => {
  // const { templateid } = useParams();
  //alert(key);
  const DynamicForm = GetForm(templateid);
  const PromptGeneratorForm = GetForm("generate-ai-content-prompt");
  const { state, dispatch } = useContext(StoreContext);
  const [activeResultTab, setActiveResultTab] = useState('form');
  const [resultList, setResultList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [apiInProgress, setAPIInProgress] = useState(false);
  const [savingNote, setSavingNote] = useState(false);
  const [apiError, setAPIError] = useState(false);
  const [accountBalance, setAccountBalance] = useState({
    n_words: 0,
    t_minutes: 0,
    n_chars: 0,
    n_images: 0,
    only_n_words: 0,
    only_t_minutes: 0,
    only_n_chars: 0,
    only_n_images: 0,
    "GPT4:n_words": 0,
    subscribed: false
});
  const [triggerAccountBalance, setTriggerAccountBalance] = useState(true);
  const [triggerLoadHistory, setTriggerLoadHistory] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [enablePrevPage, setEnablePrevPage] = useState(false);
  const [enableNextPage, setEnableNextPage] = useState(false);

  const [promptId, setPromptId] = useState('generate:title');
  const [transcriptionUUID, setTranscriptionUUID] = useState(null);

  const [currentPromptTab, setCurrentPromptTab] = useState('other');

  const [promptList, setPromptList] = useState(allPrompts);

  const [showPromptCustomize, setShowPromptCustomize] = useState(false);
  const [selectedPrompts, setSelectedPrompts] = useState(["other", "generate:title", "generate:adsscript", "generate:BlogPost"]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [activeKey, setActiveKey] = useState(null);

  let abortController = new AbortController();

  const handleCollapseChange = (key) => {
    setActiveKey(key);
    // console.log('Expanded Panel Key:', key); // This will log the key of the expanded panel
  };

  const isExpanded = (key) => key === activeKey;

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const closeModal = () => {
    setShowPromptCustomize(false);
  };

  useEffect(() => {
      if(transcriptionId) {
        setTranscriptionUUID(transcriptionId);
      }
  }, [transcriptionId]);

  useEffect(() => {
      if(savedPrompts) {
        const combinedList = [...selectedPrompts, ...savedPrompts];
        const uniqueList = [...new Set(combinedList)];
        setSelectedPrompts(uniqueList);
      }
  }, [savedPrompts]);


useEffect(() => {
    handleSavePromptList(selectedPrompts);  
}, [selectedPrompts]);

  const refreshTemplateHistory = (page) => {
      if(abortController) {
        abortController.abort();
      }
      // Call API to create transcription
      let url = api_config.BASE_BACKEND_ENDPOINT + 'api/template-history/?template=' + templateid + '&prompt=' + currentPromptTab + '&transcriptionId=' + transcriptionId;
      if(page) {
        url += "&page=" + page;
      }
      abortController = new AbortController();
      const signal = abortController.signal;
      let payload = {};

      UseAPI(signal, url, 'GET', payload)
        .then(function (data) {
          // handle data here
          if(data.results) {
            setHistoryList(data.results);
            setCurrentPage(data.current_page);
            if (!data.prev_page) {
              setEnablePrevPage(false);
            } else {
              setEnablePrevPage(true);
            }

            if (!data.next_page) {
              setEnableNextPage(false);
            } else {
              setEnableNextPage(true);
            }

            if (data.total_page === 0) {
              setEnablePrevPage(false);
              setEnableNextPage(false);
            }
          }
          else {
            setHistoryList([]);
          }
        })
        .catch(function (err) {
          if (err.name === 'AbortError') {
            console.log('Fetch aborted');
          } else {
            //alert("Error");
            message.error('Error connecting to server');
          }
          setEnablePrevPage(false);
          setEnableNextPage(false);
        });
  }

  const handlePrevPagClick = (event) => {
    let prevPage = currentPage - 1;
    if (prevPage < 0) {
      setEnablePrevPage(false);
    }
    setCurrentPage(prevPage);
    refreshTemplateHistory(prevPage);
  };

  const handleNextPagClick = (event) => {
    let nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    refreshTemplateHistory(nextPage);
  };

  useEffect(() => {
    if(transcriptionUUID) {
      refreshTemplateHistory();
    }
    return () => {
      
    };
  }, [triggerLoadHistory, templateid, transcriptionUUID, currentPromptTab]);
  
  useEffect(() => {
    // Call API to create transcription
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/account-balance/';
    const abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {
      "other_credits": ["GPT4:n_words"],
      "llm": true
    }

    UseAPI(signal, url, "POST", payload)
    .then(function(data) {
        // handle data here
        if(data.code === 200) {
            setAccountBalance(data.balance);
            setSupportedLLM(data.llm);
        }
        else {
            message.error("Error connecting to server");
        }
    })
    .catch(function(err) {
        if (err.name === 'AbortError') {
            console.log('Fetch aborted');
        } else {
            //alert("Error");
            // message.error("Error connecting to server");
        }
    });

    // Abort fetch when component unmounts
    return () => {
      abortController.abort();
    };
}, [triggerAccountBalance]);

  if (!DynamicForm) {
    // window.location.href = '/page-not-found';
  }

  // Access the global state
  // const someValue = state.someValue;

  // Dispatch actions to update the global state
  //dispatch({ type: 'UPDATE_VALUE', payload: value });

  useEffect(() => {
      setResultList([]);
  }, [templateid]);

  const handleDynamicFieldInputChange = (event, formData) => {
    // console.log(formData["content-generator:prompt"].value);
    setPromptId(formData["content-generator:prompt"].value);
    //that.setState({dynamic_field_form_data: formData});
    //console.log(that.state.dynamic_field_form_data);
    //console.log(state.name);
  };

  const updateFormSubmitStatus = (submitStatus, result_list, error_code, error) => {
    if (submitStatus === 'in-progress') {
      setAPIInProgress(true);
      setAPIError(false);
    } else if (submitStatus === 'success') {
      setResultList([...result_list]);
      setTriggerAccountBalance(!triggerAccountBalance);
      setTriggerLoadHistory(!triggerLoadHistory);
      setAPIInProgress(false);
      setAPIError(false);
    } else {
      // failed
      if (error_code) {
        setAPIInProgress(false);
        setAPIError(true);
        if (error_code === 429) {
          notification.error({
            message: 'Error in processing request',
            description:
              'The request could not be processed. If this continues after a few attempts, you may have reached the max limit of this chat length. Please try creating a new chat',
          });
        } else if (error_code === 430) {
          notification.error({
            message: 'Too many concurrent requests',
            description:
              'You are trying more than one concurrent requests. Only one request is allowed at a time. ',
          });
        } else if (error_code === 406) {
          notification.error({
            message: 'You have reached the limit',
            description:
              'You have reached the word limit. Please upgrade your plan or contact support at support@chatscribe.pro',
          });
        } else {
          notification.error({
            message: 'Error processing your request',
            description:
              'The request could not be processed. If this continues after a few attempts, please contact at support@chatscribe.pro',
          });
        }
      } else {
        if (error && error.name && error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          //alert("Error");
          setAPIInProgress(false);
          setAPIError(true);
        }
      }
    }
  };

  const handleSavePromptList = (promptList) => {
    if(!transcriptionId) {
      return;
    }
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/t/' + transcriptionId + '/save-prompts-tab/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    UseAPI(signal, url, 'PUT', {
      prompt_ids: promptList
    })
      .then(function () {
        
      })
      .catch(function (err) {
        
      });
  };

  const handleContentCopy = (item) => {
    const htmlString = ReactDOMServer.renderToString(<ReactMarkdown>{item}</ReactMarkdown>);

    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    document.body.appendChild(tempElement);
    const range = document.createRange();
    range.selectNode(tempElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    document.body.removeChild(tempElement);

    //navigator.clipboard.writeText(htmlString);

    message.success('Copied');
  };

  const handleContentDownload = (title, markdownContent) => {
      const htmlString = ReactDOMServer.renderToString(<ReactMarkdown>{markdownContent}</ReactMarkdown>);

      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString;
      document.body.appendChild(tempElement);

      const blob = new Blob([tempElement.innerText], { type: 'text/plain' });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger download
      const a = document.createElement('a');
      a.href = url;
      a.download = title + '.txt'; // Specify the file name for download
      document.body.appendChild(a); // Append the anchor to the body

      a.click(); // Trigger the download

      document.body.removeChild(a); // Clean up and remove the anchor element
      document.body.removeChild(tempElement);
      URL.revokeObjectURL(url); // Release the Blob URL to free up resources
  };


  const handlePDFDownload = (title, markdownContent) => {
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/export-markdown/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {
      "file_name": title,
      "content": markdownContent
    };
    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        // handle data here
        if (data.blob) {
          // Create a URL for the blob
          const url = window.URL.createObjectURL(new Blob([data.blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', title + '.pdf'); // or any other extension
          document.body.appendChild(link);
          link.click();
  
          link.parentNode.removeChild(link);
        } else {
          // Handle non-blob responses or errors here
          console.error('Failed to download file:');
        }
      })
      .catch(function (err) {
        console.error('Failed to download file:');
      });
};


  const handleCustomAction = () => {
    setShowPromptCustomize(true);
  };

  const handleTabClick = (key, event) => {
      setCurrentPromptTab(key);
  };

  const onCheckboxChange = (e, promptItem) => {
    if (e.target.checked) {
      // Add to selected prompts
      setSelectedPrompts([...selectedPrompts, promptItem]);
    } else {
      // Remove from selected prompts
      setSelectedPrompts(selectedPrompts.filter(item => item !== promptItem));
    }
  };

  return (
    <PageLayout>
      <div className="template-form-container" style={{flexDirection: 'column'}}>
        <div className="dynamic-form-container dynamic-form-result-container"> 
        <Tabs tabBarExtraContent={{
            right: (
              <Button onClick={handleCustomAction}>Customize</Button>
            ),
          }}
          onTabClick={handleTabClick}>
          {
            selectedPrompts.map((selectedValue, index) => {
              const item = promptList.find(item => item.value === selectedValue);
              return item ? (
                <TabPane className='prompt-tab' tab={item.label} key={`${item.value}`}>
                    {item.value==="other" && 
                      <DynamicFormRenderer
                        form={DynamicForm}
                        handleInputChange={handleDynamicFieldInputChange}
                        onResult={updateFormSubmitStatus}
                        transcriptionId={transcriptionId}
                        n_words={accountBalance.n_words}
                        subscribed={accountBalance.subscribed}
                        formId={templateid}
                        showBottomToolbar={!DynamicForm.hideBottomToolbar}
                      ></DynamicFormRenderer>
                    }
                    {item.value!=="other" && 
                      <DynamicFormRenderer
                        form={PromptGeneratorForm}
                        handleInputChange={handleDynamicFieldInputChange}
                        onResult={updateFormSubmitStatus}
                        transcriptionId={transcriptionId}
                        n_words={accountBalance.n_words}
                        subscribed={accountBalance.subscribed}
                        formId={templateid}
                        initialFormData={{
                          "content-generator:prompt": { key: item.value, value: item.value }
                        }}
                        submitBtnLabel="Generate"
                      ></DynamicFormRenderer>
                    }
                    <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 'calc(100vh - 290px)', overflowY: 'auto' }}>
                    {historyList.length == 0 && (
                      <p style={{ padding: '30px', fontSize: '1rem', textAlign: 'center' }}>
                        No result found
                      </p>
                    )}
                    {historyList.length > 0 && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Collapse accordion activeKey={activeKey} onChange={handleCollapseChange}>
                            {historyList.map((historyItem, index) => (
                              <Panel
                                header={
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', alignItems: 'center' }}>
                                      <div style={{fontSize: '1.3rem'}}>{historyItem.prompt}</div>
                                      <span style={{ fontSize: '0.8rem', marginLeft: '13px' }}>{new Date(historyItem.completed_on).toLocaleString()}</span>
                                      </div>
                                      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'end', alignItems: 'center' }}>
                                        <Tooltip title={'Copy'}>
                                          <span style={{cursor: 'pointer'}} onClick={(e) => {
                                            e.stopPropagation();
                                            handleContentCopy(historyItem.results[0], historyItem.results[0])
                                          }}>
                                            <CopyOutlined />
                                          </span>
                                        </Tooltip>
                                        <Tooltip title={'Download Text'}>
                                        <span onClick={(e) => { 
                                            e.stopPropagation();
                                            handleContentDownload(historyItem.prompt, historyItem.results[0]);
                                         }} style={{ cursor: 'pointer', marginLeft: '13px' }}>
                                          <FileTextOutlined />
                                        </span>
                                        </Tooltip>
                                        <Tooltip title={'Export to Docx'}>
                                          <Button className='shortcut' 
                                            style={{padding: '3px 8px', border: 'none', background: 'none', marginRight: '0px', marginBottom: '-8px'}}
                                            disabled={apiInProgress}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              const htmlString = ReactDOMServer.renderToString(<ReactMarkdown>{historyItem.results[0]}</ReactMarkdown>);

                                              // Create a temporary element to hold the rendered HTML content
                                              const tempElement = document.createElement("div");
                                              tempElement.innerHTML = htmlString;
                                              document.body.appendChild(tempElement);

                                              // Extract the plain text from the temporary element
                                              const textToCopy = tempElement.textContent;
                                              document.body.removeChild(tempElement);

                                              let abortController = new AbortController();
                                              const signal = abortController.signal;
                                              DownloadDocxAPI(signal, textToCopy, `${historyItem.prompt}.docx`);
                                            }}
                                          >
                                            <DocxIcon size={20} />                              
                                          </Button>
                                          </Tooltip>
                                        <Badge count={historyItem.engine} style={{ backgroundColor: 'transparent', color: '#333', height: 'auto', marginLeft: '0px' }} />
                                      </div>
                                    </div>
                                    {
                                      !isExpanded(index.toString()) && <div>
                                        <div>{renderNWords(historyItem.results[0])}</div>
                                      </div>
                                    }
                                  </div>
                                }
                                key={index}
                              >
                                <div style={{ marginTop: '3px' }}>
                                  <div style={{ background: 'aliceblue', padding: '0px 5px', fontWeight: '600' }}>
                                    <ReactMarkdown>{historyItem.prompt}</ReactMarkdown>
                                  </div>
                                  <div>
                                    <ReactMarkdown>{historyItem.results[0]}</ReactMarkdown>
                                  </div>
                                </div>
                              </Panel>
                            ))}
                          </Collapse>
                          <div className="template-pagination">
                            <Button
                              disabled={!enablePrevPage}
                              onClick={(event) => handlePrevPagClick(event)}
                              className={`template-pagination-action ${enablePrevPage ? '' : 'disabled'}`}
                            >
                              <ChevronLeft />
                            </Button>
                            <Button
                              disabled={!enableNextPage}
                              onClick={(event) => handleNextPagClick(event)}
                              className={`template-pagination-action ${enableNextPage ? '' : 'disabled'}`}
                            >
                              <ChevronRight />
                            </Button>
                          </div>
                      </div>
                    )}
                  </div>
              </TabPane>
              ) : null;
            })
          }
        </Tabs>
        </div>
        {
          showPromptCustomize && 
          <div className="overlay">
              <div className="dialog">
                  <span className="close-button" onClick={closeModal}>&#10006; Close</span>
                  <h2>Customize Prompts 
                  </h2>
                  <div className="text-script">
                  {promptList.map((promptItem, index) => (
                    <div style={{padding: '10px'}} key={index}>
                      <Checkbox 
                        onChange={(e) => onCheckboxChange(e, promptItem.value)}
                        checked={selectedPrompts.includes(promptItem.value)}
                        disabled={promptItem.value === "other"}
                      >
                        {promptItem.label}
                      </Checkbox>
                    </div>
                  ))}
                  </div>
              </div>
          </div>
        }
      </div>
    </PageLayout>
  );
};

export default TemplateForm;