import React from 'react';
import Img1 from '../../assets/img/ChatScribePro2.gif';


const LPPromoItem1 = () => {
  return (
    <div className="slider-image">
        <div className="slider-content">
          <img src={Img1}/>
        </div>
      </div>
  );
};

export default LPPromoItem1;
