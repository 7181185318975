import React, { useState, useCallback, useEffect } from 'react';
import { message , Button} from 'antd';
import { useDropzone } from 'react-dropzone';
import UseAPI from 'lib/api/UseAPI';
import api_config from "lib/api/common"
import { CheckCircleOutlined, LinkOutlined, LoadingOutlined } from '@ant-design/icons';


const AudioUploader = ({ availableMinutes, onDataAvailable, enableSubmit, onYoutubeMetaData }) => {
  const [audioFile, setAudioFile] = useState(null);
  const [audioURL, setAudioURL] = useState('');
  const [uploadedFileType, setUploadedFileType] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [maxDuration, setMaxDuration] = useState(availableMinutes);
  useEffect(() => {
    setMaxDuration(availableMinutes);
  }, [availableMinutes]);

  const onDrop = useCallback((acceptedFiles) => {
    setIsEnableYoutube(false)
    const file = acceptedFiles[0];

    const fileType = file.type;
    const fileSize = file.size;
    //alert(fileSize);
    const fileExtension = '.' + file.name.split('.').pop();
    const maxSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes

      if (file.size > maxSize) {
        message.error('Please upload a file smaller than 2GB');
        return;
      }

      setAudioFile(file);
      setAudioURL(URL.createObjectURL(file));

      let upFileType = null;
      if(fileType.startsWith('audio/')) {
        setUploadedFileType("audio");
        upFileType = "audio";
      }
      else if(fileType.startsWith('video/')) {
        setUploadedFileType("video");
        upFileType = "video";
      }

      onDataAvailable(file, 0, upFileType);
      enableSubmit();
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const dropZoneCSS = (hideBorder) => {
    // CSS for dropzone styling
  };

  const [videoURL, setVideoURL] = useState('');
  const [videoAPIInProgress, setVideoAPIInProgress] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isURLEnble, setIsEnableYoutube] = useState(true);

  const handleInputChange = (e) => {
    setVideoURL(e.target.value);
    const inputUrl = event.target.value;
    // Regular expression to match YouTube URLs
    const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

    // Test the input URL against the regex
    const isUrlValid = youtubeRegex.test(inputUrl);

    setVideoURL(inputUrl);
    setIsValid(isUrlValid);

  };

  const onHandleYoutubeSubmit = (e) => {
    if(typeof e !== "undefined" && e) {
      e.preventDefault();
    }
    
    if(videoAPIInProgress) {
      return false;
    }

    if(!videoURL) {
      return false;
    }

    setVideoAPIInProgress(true);

    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/t/fetch-metadata/';
    api_config.ABORT_CONTROLLER = new AbortController();
    const signal = api_config.ABORT_CONTROLLER.signal;
    let payload = {
      "action": "fetch_metadata",
      "url": videoURL
      };
    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        setVideoAPIInProgress(false);
        if(data.code === 200) {
          const creditsRequired = data.metadata.credit_required;
          if (creditsRequired <= availableMinutes) {
             onYoutubeMetaData(data.metadata, videoURL);
             onDataAvailable(videoURL, data.metadata.duration, "url");
             setIsEnableYoutube(false)
             setAudioFile(!audioFile)
          } else {
            message.error(`The video could not be processed due to insufficient credits. ${creditsRequired} credit required.`);
          }
        }
        else {
          message.error("Error in fetching the video. Please try again later.");
        }
      })
      .catch(function (err) {
        setVideoAPIInProgress(false);
      });
    // Reset the input value
    //setVideoURL('');
  };
  return (
    <div className='audio-upload-preview'>
    {  false && isURLEnble &&  <div className='youtube-url'>
     <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'start', alignItems: 'center'}}>
     <LinkOutlined />
      <input type="text" placeholder='Drop youtube url..' value={videoURL} onFocus={() => {
          //setIsValid(true);
      }} onBlur={() => {
        onHandleYoutubeSubmit();
      }} onChange={handleInputChange}    
       style={{ borderColor: isValid ? 'black' : 'red' }}/>
      <Button className='yt-upload-btn' type="primary" style={{ position: "relative", borderRadius: "5px" }} onClick={onHandleYoutubeSubmit}>
        {
          videoAPIInProgress?<><LoadingOutlined /> Processing</>:<>Proceed</>
        }
        </Button>
      </div>
      {!isValid && <p style={{ color: 'red',marginLeft: "15px",marginBottom: "0px !important" }}>Please enter a valid YouTube URL.</p>}

     <div style={{textAlign: "center", marginTop: '13px', marginBottom: '-10px', opacity: '0.5'}}>OR</div>
    </div>}
      {!audioFile && (
        <div
          {...getRootProps()}
          style={{
            border: '2px dashed #bababa',
            padding: '20px',
            width: '100%',
            height: '90%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor:"pointer",
            background: "rgb(247, 247, 247)"
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p style={{ opacity: '0.5' }}>Drop the audio file here...</p>
          ) : (
            <div>
            <p style={{ opacity: '0.5' }}>Drag and drop an audio or video file here, or click to select one. </p>
            <p style={{ opacity: '0.5', textAlign: 'center' }}>(mp3, wav, webm, and mp4 files are supported)</p>
            </div>
          )}
        </div>
      )}
      {audioFile && (
        <div
          style={{
            padding: '20px',
            width: '100%',
            height: '100%',
            minHeight: uploadedFileType === "video"?'200px':'200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {
            false && uploadedFileType === "audio" && 
            <audio style={{ width: '100%' }} controls src={audioURL} />
          }
          {
            false && uploadedFileType === "video" && 
            // <video style={{ width: '100%', height: '100%' }} controls src={audioURL} />
            // <video style={{ width: '100%', height: '100%' }} controls>
            //   <source src={audioURL} type="video/mp4"></source>
            //   Your browser does not support the video tag.
            // </video>
            <h3 style={{fontSize: '1.4rem', opacity: '0.6'}}><CheckCircleOutlined size={'30px'}/> Ready to Transcribe</h3>
          }
          <h3 style={{fontSize: '1.4rem', fontWeight: '600'}}><CheckCircleOutlined size={'30px'}/> Ready to Transcribe</h3>
        </div>
      )}
    </div>
  );
};

export default AudioUploader;
