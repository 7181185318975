import React from 'react';

const GoogleDriveIcon = ({ width = 2500, height = 2166, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 1443.061 1249.993"
    {...props}
  >
    <path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z" />
    <path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z" />
    <path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z" />
  </svg>
);

export default GoogleDriveIcon;
