import React, { useContext, useEffect, useState } from 'react';
import PageLayout from '../../layout/PageLayout';
import AudioRecorder from './AudioRecorder';
import AudioUploader from './AudioUploader';
import { useNavigate } from 'react-router-dom';
import { Select, Form, message, Radio, Card } from 'antd';
import { Button, Tooltip, notification } from 'antd';
const { Option } = Select;
import { Switch } from 'antd';
import { Spin } from 'antd';
import "./index.css"
import AWSS3Uploader from './AWSS3Uploader';
import { AudioOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import UseAPI from 'lib/api/UseAPI';
import api_config from "lib/api/common"
import { TRANSCRIPTION_AUDIO_TOPIC, TRANSCRIPTION_OUTPUT_LANGUAGES } from './TranscriptionData';
import { isFeatureAvailable, StoreContext } from 'store';
import GoogleDriveIcon from 'icons/GoogleDriveIcon';


const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 16,
    }}
    spin
  />
);


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
];

const tableData = [
  {
    key: '1',
    name: 'John Doe',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'Jane Doe',
    age: 42,
    address: '11 Downing Street',
  },
];

const outputFormats = [
  {
    id: 'text',
    name: 'Plain text'
  },
  {
    id: 'vtt',
    name: 'Subtitle'
  }
];


const NewTranscription = ({availableMinutes, statusCheckCallback}) => {
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const [audioLang, setAudioLang] = useState(null);
  const [audioTopic, setAudioTopic] = useState("general");
  const [audioOption, setAudioOption] = useState("1");
  const [transData, setTransData] = useState(null);
  const [detectSpeaker, setDetectSpeaker] = useState(false);
  const [enhancedQuality, setEnhancedQuality] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgrress] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const [uploadedFileType, setUploadedFileType] = useState(null);

  const [enableSubmit, setEnableSubmit] = useState(false);

  // Permissions
  const [maskPIIData, setMaskPIIData] = useState(false);
  const [enableProfanity, setEnableProfanity] = useState(false);
  const [transcriptionInProgress, setTranscriptionInProgress] = useState(false);

  const [cancelState, setCancelState] = useState(false);

  const { state, dispatch } = useContext(StoreContext);


  const resetForm = () => {
    setAudioOption("0");
    setAudioLang(null);
    setUploadedFileType(null);
    setDetectSpeaker(false);
    setEnhancedQuality(false);
    setAudioData(null);
    setUploading(false);
    setUploadProgrress(0);
    setEnableSubmit(false);
  }

  useEffect(() => {
    if (audioOption === "0") {
      setAudioOption("1");
    }
  }, [audioOption]);  

  const enableSubmitInForm = () => {
    setEnableSubmit(true);
  };

  const navigate = useNavigate();

  const radioOptions = [
    {
        key: "1",
        label: "Upload"
    },
    {
      key: "2",
      label: "Record Audio"
    }
    
  ]

  useEffect(() => {
     
  }, [audioOption, uploadedFileType]);

  useEffect(() => {
    setHeaders([...columns]);
    setData([...tableData])
  }, []);

  const recordingOptionChangeHandler = (e, value) => {
    setAudioOption(value);
    setAudioData(null);
    setCancelState(false);
  }

  function handleAudioLangChange(value) {
    setAudioLang(value);
  }

  function handleAudioTopicChange(value) {
    setAudioTopic(value);
  }

  const handleDSSwitchChange = (checked) => {
    setDetectSpeaker(checked);
  };

  const handleEQSwitchChange = (checked) => {
    setEnhancedQuality(checked);
  };

  const handlePIISwitchChange = (checked) => {
     setMaskPIIData(checked);
  }

  const handleProfanitySwitchChange = (checked) => {
    setEnableProfanity(checked);
 }

  const handleSubmit = (event) => {

    if(availableMinutes <= 0) {
       notification.error({
        message: 'Insufficient Credit',
        description: "You do not have sufficient credit",
      })
       return false;
    }

    setURLBtnEnable(false)
    setEnableSubmit(false);
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/account-balance/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {}

    UseAPI(signal, url, "POST", payload)
    .then(function(data) {
        // handle data here
        //console.log(data.balance.t_minutes, audioDuration);
        //alert(audioDuration);
        let durationMinutes = Math.ceil(audioDuration/60);
        if(data.code === 200) {
            if(data.balance.t_minutes<=-99999999 || data.balance.t_minutes >= durationMinutes) {
              setUploading(true);
            }
        }
        else {
            setEnableSubmit(true);
            message.error("Error connecting to server");
        }
    })
    .catch(function(err) {
        setEnableSubmit(true);
        if (err.name === 'AbortError') {
            console.log('Request aborted');
        } else {
            //alert("Error");
            message.error("Error connecting to server");
        }
    });
      
  }

  const handleUploadProgress = (progress) => {
    setURLBtnEnable(false)

    //console.log(`Upload progress: ${progress}%`);
    setUploadProgrress(progress);
  };
  const [metaData,setMetaData] = useState(null)
  const [ytURL,setYTUrl] = useState(null)
  const [urlBtn,setURLBtnEnable] = useState(null)

  const onYoutubeMetaData =(data,url)=>{
    setMetaData(data)
    setYTUrl(url)
   // setUploading(true);
    setURLBtnEnable(true);
    setEnableSubmit(true)
   // setAudioData(true)
  }
  const onSubmitFetchData =(event)=>{
    event.preventDefault();
    setEnableSubmit(false);
    // console.log('Upload completed:', data);
    // Call API to create transcription
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/t/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {
        fetch_url:ytURL,
        original_file: metaData.title,
        file:ytURL,
        audio_language: audioLang,
        audio_topic: audioTopic,
        detect_speaker: detectSpeaker,
        enhance_quality: enhancedQuality,
        profanity_filter: enableProfanity,
        redact_pii: maskPIIData,
        audio_duration: metaData.duration
        
    }
    UseAPI(signal, url, "POST", payload).then(function(data) {
        setTranscriptionInProgress(false);
        setURLBtnEnable(false);
        //navigate('/dubbing-studio/' + data.uuid);
        // setTransData(null);
        statusCheckCallback();
        resetForm();
        notification.info({
          message: 'Submitted',
          description: "Your file is being processed. Once processing is completed transcription status will be changed. Please wait a while and check the status.",
        })
    }).catch(function(err) {
        setEnableSubmit(true);
        setTranscriptionInProgress(false);
        //console.log(err);
        //alert("Error");
    });

  }
  const handleUploadCompleted = (data) => {
    // console.log('Upload completed:', data);
    setUploading(true);
    
    setTranscriptionInProgress(true);
    setURLBtnEnable(false)

    //console.log(audioData);

    // Call API to create transcription
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/t/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {
        original_file: audioData.name,
        file: data.Location,
        audio_language: audioLang,
        audio_topic: audioTopic,
        detect_speaker: detectSpeaker,
        enhance_quality: enhancedQuality,
        profanity_filter: enableProfanity,
        redact_pii: maskPIIData,
        audio_duration: audioDuration
    }
    UseAPI(signal, url, "POST", payload).then(function(data) {
        setTranscriptionInProgress(false);
        //navigate('/dubbing-studio/' + data.uuid);
        // setTransData(null);
        statusCheckCallback();
        resetForm();
        notification.info({
          message: 'Submitted',
          description: "Your file is being processed. Once processing is completed transcription status will be changed. Please wait a while and check the status.",
        })
    }).catch(function(err) {
        setTranscriptionInProgress(false);
        //console.log(err);
        //alert("Error");
    });

  };

  const handleUploadFailed = (err) => {
    setURLBtnEnable(false)

    //console.error('Upload failed:', err);
    setUploading(false);
    setTranscriptionInProgress(false);
  };

  const tooltipUpgrade = "<span>Upgrade your plan to use this feature</span>";

  return (
    <PageLayout title="Transcription">
      <div className='transcription-container'>
          <div className='trans-form-container'>
              {
                 transData === null && 
                 <div className='trans-form'>
                  { audioData === null && 
                    <Radio.Group value={audioOption} onChange={(e) => {
                        setAudioOption(e.target.value);
                    }} className="rich-radio-group">
                    <Card
                        className={`transcription-card ${audioOption === "1" ? "selected" : ""}`}
                        onClick={() => setAudioOption("1")}
                        hoverable
                    >
                        <Radio.Button value="1" style={{ display: 'none' }} />
                        <UploadOutlined height={'1.6rem'} style={{ fontSize: '34px' }} />
                        <p>Upload</p>
                    </Card>
                    <Card
                        className={`transcription-card ${audioOption === "2" ? "selected" : ""}`}
                        onClick={() => setAudioOption("2")}
                        hoverable
                    >
                        <Radio.Button value="2" style={{ display: 'none' }} />
                        <AudioOutlined style={{ fontSize: '34px' }} />
                        <p>Record</p>
                    </Card>
                    {/* <Card
                        className={`transcription-card ${audioOption === "3" ? "selected" : ""}`}
                        onClick={() => setAudioOption("3")}
                        hoverable
                    >
                        <Radio.Button value="3" style={{ display: 'none' }} />
                        <GoogleDriveIcon width={'16px'} height={'12.5px'} />
                        <p>Google Drive</p>
                    </Card> */}
                </Radio.Group>
                  }
                  <div className='trans-form-inner' style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <div>


                      <div className='transcription-option-container'>

                            {
                              audioOption==="1" && 
                              <div className='transcription-option-item2'>
                                  <AudioUploader 
                                  onYoutubeMetaData={onYoutubeMetaData}
                                   availableMinutes={availableMinutes} onDataAvailable={(data, duration, uploadFileType) => {setAudioData(data); setAudioDuration(duration); setUploadedFileType(uploadFileType);}} enableSubmit={enableSubmitInForm}/>
                                </div>
                            }           
                            {
                              audioOption==="2" && 
                              <div className='transcription-option-item'>
                                  <AudioRecorder availableMinutes={availableMinutes} onDataAvailable={(data, duration) => {setAudioData(data); setUploadedFileType("audio");}} enableSubmit={(duration) => {enableSubmitInForm(); setAudioDuration(duration);}}/>
                                </div>
                            }     
                      </div>
                      {/*<p style={{textAlign: "center",marginTop: "10px"}}>OR</p>

                      <div className='transcription-container-form'>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    <RecordingOption options={radioOptions} onChange={recordingOptionChangeHandler} defaultKey={audioOption} disabled={audioData}/>
                    </div>
                    </div>
                    */
}
                    </div>
                    {
                      audioData && 
                      <div style={{maxHeight: '360px', overflowY: 'auto'}}>
                          {
                            true && 
                            <Form.Item 
                            label={"Audio Language" + (uploadedFileType==='audio'?" spoken in the audio":"") + (uploadedFileType==='video'?" spoken in the video":"")} 
                            labelCol={{ 
                                span: 24, 
                                style: { 
                                    textAlign: 'left', 
                                    fontWeight: 'bold', 
                                    fontSize: '18px', 
                                    color: '#1890ff' 
                                } 
                            }} 
                            required
                            wrapperCol={{ span: 24 }}
                            >
                            <Select
                                  showSearch
                                  style={{ width: '300px', margin: '0 auto' }}
                                  placeholder="Select Language"
                                  initial={audioLang}
                                  optionFilterProp="children"
                                  onChange={handleAudioLangChange}
                                  value={audioLang}
                              >
                                  {TRANSCRIPTION_OUTPUT_LANGUAGES.map((lang) => (
                                  <Option key={lang.code} value={lang.code}>
                                      {lang.lang}
                                  </Option>
                                  ))}
                              </Select>
                        </Form.Item>
                          }
                        <Form.Item 
                            label="What the audio talks about?" 
                            labelCol={{ 
                                span: 24, 
                                style: { 
                                    textAlign: 'left', 
                                    fontWeight: 'bold', 
                                    fontSize: '18px', 
                                    color: '#1890ff' 
                                } 
                            }} 
                            wrapperCol={{ span: 24 }}
                            >
                            <Select
                                  style={{ width: '300px', margin: '0 auto' }}
                                  placeholder="What the audio talks about?"
                                  optionFilterProp="children"
                                  onChange={handleAudioTopicChange}
                                  value={audioTopic}
                              >
                                  {TRANSCRIPTION_AUDIO_TOPIC.map((transOption) => (
                                  <Option key={transOption.id} value={transOption.id}>
                                      {transOption.name}
                                  </Option>
                                  ))}
                              </Select>
                        </Form.Item>
                        {
                          false && 
                          <Form.Item 
                            label="Detect Speakers in the audio" 
                            labelCol={{ 
                                span: 24, 
                                style: { 
                                    textAlign: 'left', 
                                    fontWeight: 'bold', 
                                    fontSize: '18px', 
                                    color: '#1890ff' 
                                } 
                            }} 
                            wrapperCol={{ span: 24 }}
                            >
                              <Tooltip title={(!isFeatureAvailable(state, "stt_speaker_detection"))?<div><span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {window.location.href='/plan'}} className="unmixr-secondary">Upgrade</span> to use this feature</div>: ""}>
                              <Switch disabled={!isFeatureAvailable(state, "stt_speaker_detection")} checked={detectSpeaker} onChange={handleDSSwitchChange} />
                              </Tooltip>
                            
                        </Form.Item>
                        }
                      </div>
                    }
                  </div>
                  {uploading && availableMinutes > 0 && (
                    <AWSS3Uploader 
                      blob={audioData} 
                      onUploadCompleted={handleUploadCompleted} 
                      onUploadProgress={handleUploadProgress} 
                      onUploadFailed={handleUploadFailed} 
                    />
                  )}
                  <div className='transcription-container-form-submit'>
                   {
                       audioData && 
                       <Button disabled={uploading || transcriptionInProgress || !enableSubmit || !audioLang} onClick={urlBtn?onSubmitFetchData:handleSubmit} type="primary" style={{ width: '200px' }}>
                        {uploading && !transcriptionInProgress && (
                          <>
                            <Spin
                              indicator={antIcon}
                              style={{
                                marginRight: '10px',
                                marginTop: '3px',
                              }}
                            />
                            <span> Uploading {uploadProgress}%</span>
                          </>
                        )}
                        {!uploading && transcriptionInProgress && (
                          <>
                            <Spin
                              indicator={antIcon}
                              style={{
                                marginRight: '10px',
                                marginTop: '3px',
                              }}
                            />
                            <span> Transcribing</span>
                          </>
                        )}
                        {
                           !uploading && !transcriptionInProgress && 
                           <span>Transcribe</span>
                        }
                        </Button>
                    }
                    {
                       audioData && 
                       <Button disabled={uploading || transcriptionInProgress} type='secondary' style={{width: '120px', marginLeft: '10px'}} onClick={() => {resetForm()}}>Cancel</Button>
                    }
                  </div>
                </div>
              }
          </div>
      </div>
    </PageLayout>
  );
};

export default NewTranscription;
