import React from 'react';

const TransformerIcon = ({size='23px'}) => (
  <svg
    width={size}
    height={size}
    viewBox="-6.4 -6.4 76.80 76.80"
    data-name="Layer 1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    transform="rotate(90)"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke="currentColor"
      stroke-width="0.384"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path fill="currentColor" d="M32,54A22,22,0,0,0,54,32h6A28,28,0,0,1,32,60"></path>
      <path fill="currentColor" d="M32,10A22,22,0,0,0,10,32H4A28,28,0,0,1,32,4"></path>
      <polygon fill="currentColor" points="23 50 32 33 18 33 34 18 46 18 30 29 42 29 23 50"></polygon>
      <polygon fill="currentColor" points="32 12 32 2 42 7 32 12"></polygon>
      <polygon fill="currentColor" points="32 52 32 62 22 57 32 52"></polygon>
    </g>
  </svg>
);

export default TransformerIcon;
