import React, { useState, useRef, useEffect } from 'react';
import MicIcon from 'icons/MicIcon';
import { Select, Tooltip } from 'antd';
const { Option } = Select;
import { Button } from 'antd';
import "./AudioRecorder.css"
import MicIconActive from 'icons/MicIconActive';

const AudioRecorder = ({availableMinutes, onDataAvailable, enableSubmit}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [error, setError] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const mediaRecorderRef = useRef(null);
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [audioChunk, setAudioChunk] = useState(null);
  const [mBlob, setMBlob] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [maxDuration, setMaxDuration] = useState(availableMinutes);

  const startTimeRef = useRef(0);

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds % 60).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    async function initRecorder() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter(device => device.kind === 'audioinput');
        setDevices(audioDevices);
        let firstAudioDevice = audioDevices[0]?.deviceId;
        setSelectedDevice(firstAudioDevice);
        const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: firstAudioDevice, video: false } });
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        mediaRecorderRef.current.ondataavailable = (e) => {
          if (e.data.size > 0) {
            setAudioChunk(e.data);
            const duration = Math.ceil((Date.now() - startTimeRef.current) / 1000);
            setAudioDuration(duration);
          }
        };

        mediaRecorderRef.current.onstart = () => {
          startTimeRef.current = Date.now();
        };
        
        mediaRecorderRef.current.onstop = () => {
          const duration = Math.ceil((Date.now() - startTimeRef.current) / 1000);
          setAudioDuration(duration);

          let durationMinutes = Math.ceil(duration/60);
          if(durationMinutes <= maxDuration) {
            enableSubmit(duration);
          }
        };

      } catch (err) {
        setError(err.message);
      }
    }
  
    initRecorder();
  
    // Cleanup function
    return () => {
      if (mediaRecorderRef.current) {
        if (mediaRecorderRef.current.state !== 'inactive') {
          mediaRecorderRef.current.stop();
        }
        if (mediaRecorderRef.current.stream) {
          const tracks = mediaRecorderRef.current.stream.getTracks();
          tracks.forEach(track => track.stop());
        }
      }
    };
  }, []);
  

  useEffect(() => {
    //console.log(audioChunks);
    if (audioChunk) {
      setAudioChunks([...audioChunks, audioChunk]);
    }
    // Check if recording has stopped and create the blob
    if (!isRecording && audioChunks.length > 0) {
      const blob = new Blob(audioChunks, { type: "audio/webm" });
      setAudioURL(URL.createObjectURL(blob));
      setAudioChunks([]);
      setMBlob(blob);
      onDataAvailable(blob, audioDuration);
    }
  }, [audioChunk, isRecording]);


  useEffect(() => {
    //console.log(audioChunks);
    //console.log(mBlob);
    if (mBlob) {
        setAudioURL(URL.createObjectURL(mBlob));
        // console.log(audioURL);
        // console.log(isRecording);
    }
    else {
        setAudioURL(null);
    }
  }, [mBlob]);
  

  const startRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.start(1000);
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    //   const blob = new Blob(audioChunks, { type: 'video/webm' });
    //   setAudioURL(URL.createObjectURL(blob));
    //   //console.log(audioURL);
    //   setAudioChunks([]);
    }
  };  
  

  const handleAudioDeviceChange = async (value) => {
    //console.log(`selected ${value}`);
    setSelectedDevice(value);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: value } });
    mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
  }
  
  function handleAudioDeviceBlur() {
    //console.log('blur');
  }
  
  function handleAudioDeviceFocus() {
    //console.log('focus');
  }

  return (
    <div style={{width: '100%'}}>
      {!audioURL && (
        <div>
          {
             !isRecording && 
             <div className="rec-container">
                <MicIcon width="40px" height="40px" />
            </div>
          }
          {
             isRecording && 
             <div className="rec-container active">
                <MicIconActive width="40px" height="40px" />
            </div>
          }
        </div>
      )}
      {!audioURL && (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <Select
            style={{ width: 300, margin: "0 auto", marginTop: "20px" }}
            value={selectedDevice}
            placeholder="Change audio device"
            optionFilterProp="children"
            onChange={handleAudioDeviceChange}
            onFocus={handleAudioDeviceFocus}
            onBlur={handleAudioDeviceBlur}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {devices.map((device) => (
              <Option key={device.deviceId} value={device.deviceId}>
                {device.label || device.deviceId}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {!audioURL && (
        <div style={{width: '100%', display: 'flex', marginTop: '20px', justifyContent: 'center'}}>
          {isRecording && !audioURL ? (
            <Button type="danger" onClick={stopRecording} style={{ width: '300px' }}>Stop Recording - {formatTime(audioDuration)}</Button>
          ) : (
            <Tooltip title={availableMinutes <= 0 && "You have no credits availables. Please buy more credits or contact support@chatscribe.pro"}>
            <Button disabled={availableMinutes<=0} type="secondary" onClick={startRecording} style={{ width: '300px' }}>Start Recording</Button>
            </Tooltip>
          )}
        </div>
      )}
      {audioURL && !isRecording && (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <audio style={{ width: '100%' }} controls src={audioURL} />
        </div>
      )}
      {error && (
        <div className="error-container">
          <span>Error: {error}</span>
        </div>
      )}
    </div>
  );  
};

export default AudioRecorder;
