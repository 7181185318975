import React, { useEffect, useState } from 'react';
import "./ContentLoader.css"


function ContentLoader({n=3}) {
  let iList = [1, 2, 3];
  if(n == 2) {
      iList = [1, 2];
  }
  const [items, setItems] = useState(iList);

  return (
    <div style={{ overflow: 'hidden', padding: '10px' }}>
      {items.map((item, i) => (
        <div key={i} className="card" target="_blank">
          <div className="card__header" item={item} index={i}>
            <div>
              <img className="card__header header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 className="card__header header__title" id="card-title">
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div className="card__body">
            <div className="card__body body__text" id="card-details">
              <div className="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div className="card__body body__img">
              <img className="skeleton" alt="" id="cover-img" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ContentLoader;
