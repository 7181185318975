import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid'; // import the UUID generator

var uploadStatusGlobal = {};

const AWSS3Uploader = ({ blob, onUploadCompleted, onUploadProgress, onUploadFailed, folder="transcription", uuid=null, fileExt=null }) => {
  const [s3, setS3] = useState(null);

  useEffect(() => {

    //console.log("Ok");

    AWS.config.update({
      region: 'us-east-1',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:24b046ce-af08-49a7-b0e3-5b8773627f4b',
      }),
    });

    setS3(new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: 'chatscribe' },
    }));
  }, []);

  useEffect(() => {
    if (!s3 || !blob) return;

    
    if(uuid) {
      // console.log(uploadStatusGlobal);
      if(uploadStatusGlobal[uuid]) {
        return;
      }
      uploadStatusGlobal[uuid] = true;
    }

    let uniqueName = `${uuidv4()}`; // append a UUID to the blob name

    if(fileExt) {
        uniqueName += ("." + fileExt);
    }

    const upload = s3.upload({
      Key: `${folder}/${uniqueName}`, // use the unique name here
      Body: blob,
    });

    upload.on('httpUploadProgress', (evt) => {
      onUploadProgress(Math.round((evt.loaded * 100) / evt.total));
    });

    upload.send((err, data) => {
      if (err) {
        onUploadFailed(err);
      } else {
        onUploadCompleted(data);
      }
    });
  }, [s3, blob]);

  return null;
};

export default AWSS3Uploader;
