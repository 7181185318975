import React, { useReducer, useEffect, useCallback } from 'react';
import { initialState, reducer, StoreContext } from './store';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updatePlanInfo = useCallback(() => {
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/subscription-status/';
    api_config.ABORT_CONTROLLER = new AbortController();
    const signal = api_config.ABORT_CONTROLLER.signal;
    let payload = {};

    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        // handle data here
        console.log(data);
        if (data.code === 200) {
          //setSubscriptionInfo(data.plan);
          let subscriptionData = {
            plan_name: data.plan_name,
            plan: data.plan,
            other_plans_name: data.other_plans_name,
            other_plans: data.other_plans
          };
          dispatch({ type: 'UPDATE_STATE', payload: { ...subscriptionData, loading: false } });
        } else {
          dispatch({ type: 'LOAD_DATA', payload: false });
        }
      })
      .catch(function (err) {
        dispatch({ type: 'LOAD_DATA', payload: false });
      });

  }, []);

  useEffect(() => {
    updatePlanInfo();

    // Abort fetch when component unmounts
    return () => {
      api_config.ABORT_CONTROLLER.abort();
    };
  }, [updatePlanInfo]);

  return (
    <StoreContext.Provider value={{ state, dispatch, updatePlanInfo }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
