import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const BackButton = ({onClick}) => {
  const history = useNavigate();

  const handleGoBack = () => {
    if(onClick) {
      onClick();
    }
    else {
      history("/dashboard");
    }    
  };

  return (
    <Button type="default" onClick={handleGoBack} icon={<LeftOutlined />}>
      Back
    </Button>
  );
};

export default BackButton;
