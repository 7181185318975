import React, { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './HelpText.css';

const HelpText = ({ text }) => {
  const [showHelpText, setShowHelpText] = useState(false);

  const toggleHelpText = () => {
    setShowHelpText(!showHelpText);
    return false;
  };

  return (
    <div className="help-container">
      <QuestionCircleOutlined onClick={toggleHelpText} style={{marginLeft: '5px', cursor: 'pointer'}} />
      {showHelpText && <div className="help-text">{text}</div>}
    </div>
  );
};

export default HelpText;
