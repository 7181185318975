import { message } from 'antd';
import Cookies from 'js-cookie';
import api_config from './common';


const DownloadDocxAPI = async (signal, text, fileName='download.docx') => {
  let url = api_config.BASE_BACKEND_ENDPOINT + 'api/download-docx/';
  const token = localStorage.getItem('user-token');
  const csrfToken = Cookies.get('csrftoken');
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
      'X-CSRFToken': csrfToken,
    },
    body: JSON.stringify({ text: text }),
    signal,
    credentials: 'include',
  };
  const response = await fetch(url, options);

  if (response.status === 400) {
    const errorData = await response.json();
    message.error(errorData.error);
  } else if (response.status === 200) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // Append link to body
    a.click(); // Simulate click to trigger download
    a.remove(); // Remove link after download
  }
};

export default DownloadDocxAPI;
