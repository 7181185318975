import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const RecordingOption = ({ options, onChange, defaultKey, disabled }) => {
  const [value, setValue] = useState(defaultKey);

  useEffect(() => {
    setValue(defaultKey);
  }, [defaultKey]);

  const onButtonClick = (e, option) => {
    console.log('Button clicked', option.key);
    setValue(option.key);
    onChange(e, option.key);
  };

  return (
    <div>
      {options.map((option, index) => (
        <Button
          key={index}
          type={value === option.key ? 'primary' : 'default'}
          disabled={disabled}
          onClick={e => onButtonClick(e, option)}
          style={{ marginBottom: 10, marginRight: '10px', display: option.key === value ? "none" : "block"  ,cursor: option.key === value ? "auto" : "pointer" }}
          icon={value === option.key ? <CheckOutlined /> : null}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
};

export default RecordingOption;
