import React, { Suspense, lazy } from 'react';
import ReactDOM, { createRoot } from 'react-dom';
import ReactGA from 'react-ga4';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './syncfusion_pdf.css'

import App from './App';

ReactGA.initialize('G-1QPRQTZTER');
ReactDOM.render(
  <GoogleOAuthProvider clientId="678241817544-ajvvfqrhnr4tlf46dlvl94miaiglq44t.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>,
  document.querySelector('#root'),
);
