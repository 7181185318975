import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import ContentLoader from 'loader/ContentLoader';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


const LoadingComponent = () => (
  <div className="loader-container">
    <Bars
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{display: 'flex', justifyContent: 'center'}}
        wrapperClass=""
        visible={true}
      />
  </div>
);

const DashboardComponent = loadable(() => import('pages/home/Dashboard'), {
  fallback: <LoadingComponent />,
});

const AITranscription = loadable(() => import('pages/transcription/index'), {
  fallback: <LoadingComponent />,
});
const PDFCHAT = loadable(() => import('pages/transcription/chatDoc'), {
  fallback: <LoadingComponent />,
});
const NewTranscription = loadable(() => import('pages/transcription/NewTranscription'), {
  fallback: <LoadingComponent />,
});
const TranscriptionDetail = loadable(() => import('pages/transcription/TranscriptionDetail'), {
  fallback: <LoadingComponent />,
});

const DocumentDetail = loadable(() => import('pages/transcription/DocumentDetail'), {
  fallback: <LoadingComponent />,
});

const MultiDocChatComponent = loadable(() => import('pages/transcription/MultiDocChatComponent'), {
  fallback: <LoadingComponent />,
});

const CollectionListComponent = loadable(() => import('pages/transcription/CollectionListComponent'), {
  fallback: <LoadingComponent />,
});

const DocumentTranslation = loadable(() => import('pages/translation/DocumentTranslation'), {
  fallback: <LoadingComponent />,
});

const FeatureRoadmap = loadable(() => import('pages/transcription/FeatureRoadmap'), {
  fallback: <LoadingComponent />,
});
const TemplateList = loadable(() => import('pages/preset/TemplateList'), {
  fallback: <LoadingComponent />,
});
const TemplateForm = loadable(() => import('pages/preset/TemplateForm'), {
  fallback: <LoadingComponent />,
});
const TemplateTranslateForm = loadable(() => import('pages/preset/TemplateTranslateform'), {
  fallback: <LoadingComponent />,
});
const SettingsComponent = loadable(() => import('pages/settings/index'), {
  fallback: <LoadingComponent />,
});

const Settings = loadable(() => import('pages/settings/index'), {
  fallback: <LoadingComponent />,
});

const Plans = loadable(() => import('pages/plans/index'), {
  fallback: <LoadingComponent />,
});

const BuyAddons = loadable(() => import('pages/plans/BuyAddons'), {
  fallback: <LoadingComponent />,
});

const PaymentFailureComponent = loadable(() => import('pages/plans/paymentFaliure'), {
  fallback: <LoadingComponent />,
});
const PaymentSuccessComponent = loadable(() => import('pages/plans/paymentSuccess'), {
  fallback: <LoadingComponent />,
});
const BuyCreditSuccess = loadable(() => import('pages/plans/BuyCreditSuccess'), {
  fallback: <LoadingComponent />,
});
const BuyCreditFailure = loadable(() => import('pages/plans/BuyCreditFailure'), {
  fallback: <LoadingComponent />,
});

const MyPlan = loadable(() => import('pages/myplan/index'), {
  fallback: <LoadingComponent />,
});

const AIChatbot = loadable(() => import('pages/chat/Chatbot'), {
  fallback: <LoadingComponent />,
});

const NotFound = loadable(() => import('pages/auth/Notfound'), {
  fallback: <LoadingComponent />,
});

const MainLayout = loadable(() => import('layout/MainLayout'), {
  fallback: <LoadingComponent />,
});

import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import Reset from './pages/auth/reset';
import HelpPage from './pages/Help/HelpPage';

import ProtectedRoute from './pages/auth/ProtectedRoute';
import Auth from './Auth';
import StoreProvider from './StoreProvider';
import TranscriptionHome from 'pages/transcription';
import AIContentGenerator from 'pages/transcription/AIContentGenerator';
import { Bars } from 'react-loader-spinner';



const App = () => {
  
  return (
    <StoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Auth />}>
            <Route path="" element={<Login />} />
          </Route>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="transcription">
              <Route
                path=":uuid"
                element={
                  <ProtectedRoute>
                    <TranscriptionDetail />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/" element={<MainLayout />}>

            <Route path="gpt-translator">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <TemplateTranslateForm />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="document-translation">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <DocumentTranslation />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            </Route>

              
            {/* <Route path="aichat">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <AIChatbot />{' '}
                  </ProtectedRoute>
                }
              />
            </Route> */}

            <Route path="doc">
              <Route
                path=":uuid"
                element={
                  <ProtectedRoute>
                    <DocumentDetail />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path="collection">
              <Route
                path=":uuid"
                element={
                  <ProtectedRoute>
                    <MultiDocChatComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>

          <Route path="features-roadmap">
              <Route
                index
                element={
                  <FeatureRoadmap />
                }
              />
            </Route>
          
          <Route path="/" element={<MainLayout />}>
            <Route path="buy-credits">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <BuyAddons />{' '}
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="payment-success">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <PaymentSuccessComponent />{' '}
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="payment-failure">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <PaymentFailureComponent />{' '}
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="credit-purchase-success">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <BuyCreditSuccess />{' '}
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="credit-purchase-failure">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <BuyCreditFailure />{' '}
                    </ProtectedRoute>
                  }
                />
              </Route>
            <Route path="collection">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <CollectionListComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="payment-success">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <PaymentSuccessComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="payment-failure">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <PaymentFailureComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              index
              element={
                <ProtectedRoute>
                  <DashboardComponent />
                </ProtectedRoute>
              }
            />

            <Route path="dashboard">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <DashboardComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path="help" element={<HelpPage />} />
            <Route path="settings">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Settings />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="plan">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Plans />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="myplan">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <MyPlan />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="transcriptions">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <AITranscription />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="doc">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <PDFCHAT />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
          
          <Route path="settings">
            <Route
              index
              element={
                <ProtectedRoute>
                  <SettingsComponent />{' '}
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </StoreProvider>
  );
};
export default App;
