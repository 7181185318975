export const TRANSLATOR_LANG_LIST = [
    { "label": "Afrikaans", "value": "af" },
    { "label": "Albanian", "value": "sq" },
    { "label": "Amharic", "value": "am" },
    { "label": "Arabic", "value": "ar" },
    { "label": "Armenian", "value": "hy" },
    { "label": "Assamese", "value": "as" },
    { "label": "Azerbaijani (Latin)", "value": "az" },
    { "label": "Bangla", "value": "bn" },
    { "label": "Bashkir", "value": "ba" },
    { "label": "Basque", "value": "eu" },
    { "label": "Bosnian (Latin)", "value": "bs" },
    { "label": "Bulgarian", "value": "bg" },
    { "label": "Cantonese (Traditional)", "value": "yue" },
    { "label": "Catalan", "value": "ca" },
    { "label": "Chinese (Literary)", "value": "lzh" },
    { "label": "Chinese Simplified", "value": "zh-Hans" },
    { "label": "Chinese Traditional", "value": "zh-Hant" },
    { "label": "chiShona", "value": "sn" },
    { "label": "Croatian", "value": "hr" },
    { "label": "Czech", "value": "cs" },
    { "label": "Danish", "value": "da" },
    { "label": "Dari", "value": "prs" },
    { "label": "Divehi", "value": "dv" },
    { "label": "Dutch", "value": "nl" },
    { "label": "English", "value": "en" },
    { "label": "Estonian", "value": "et" },
    { "label": "Faroese", "value": "fo" },
    { "label": "Fijian", "value": "fj" },
    { "label": "Filipino", "value": "fil" },
    { "label": "Finnish", "value": "fi" },
    { "label": "French", "value": "fr" },
    { "label": "French (Canada)", "value": "fr-ca" },
    { "label": "Galician", "value": "gl" },
    { "label": "Georgian", "value": "ka" },
    { "label": "German", "value": "de" },
    { "label": "Greek", "value": "el" },
    { "label": "Gujarati", "value": "gu" },
    { "label": "Haitian Creole", "value": "ht" },
    { "label": "Hausa", "value": "ha" },
    { "label": "Hebrew", "value": "he" },
    { "label": "Hindi", "value": "hi" },
    { "label": "Hmong Daw (Latin)", "value": "mww" },
    { "label": "Hungarian", "value": "hu" },
    { "label": "Icelandic", "value": "is" },
    { "label": "Igbo", "value": "ig" },
    { "label": "Indonesian", "value": "id" },
    { "label": "Inuinnaqtun", "value": "ikt" },
    { "label": "Inuktitut", "value": "iu" },
    { "label": "Inuktitut (Latin)", "value": "iu-Latn" },
    { "label": "Irish", "value": "ga" },
    { "label": "Italian", "value": "it" },
    { "label": "Japanese", "value": "ja" },
    { "label": "Kannada", "value": "kn" },
    { "label": "Kazakh", "value": "kk" },
    { "label": "Khmer", "value": "km" },
    { "label": "Kinyarwanda", "value": "rw" },
    { "label": "Klingon", "value": "tlh-Latn" },
    { "label": "Klingon (plqaD)", "value": "tlh-Piqd" },
    { "label": "Konkani", "value": "gom" },
    { "label": "Korean", "value": "ko" },
    { "label": "Kurdish (Central)", "value": "ku" },
    { "label": "Kurdish (Northern)", "value": "kmr" },
    { "label": "Kyrgyz (Cyrillic)", "value": "ky" },
    { "label": "Lao", "value": "lo" },
    { "label": "Latvian", "value": "lv" },
    { "label": "Lithuanian", "value": "lt" },
    { "label": "Lingala", "value": "ln" },
    { "label": "Lower Sorbian", "value": "dsb" },
    { "label": "Luganda", "value": "lug" },
    { "label": "Macedonian", "value": "mk" },
    { "label": "Maithili", "value": "mai" },
    { "label": "Malagasy", "value": "mg" },
    { "label": "Malay (Latin)", "value": "ms" },
    { "label": "Malayalam", "value": "ml" },
    { "label": "Maltese", "value": "mt" },
    { "label": "Maori", "value": "mi" },
    { "label": "Marathi", "value": "mr" },
    { "label": "Mongolian (Cyrillic)", "value": "mn-Cyrl" },
    { "label": "Mongolian (Traditional)", "value": "mn-Mong" },
    { "label": "Myanmar", "value": "my" },
    { "label": "Nepali", "value": "ne" },
    { "label": "Norwegian", "value": "nb" },
    { "label": "Nyanja", "value": "nya" },
    { "label": "Odia", "value": "or" },
    { "label": "Pashto", "value": "ps" },
    { "label": "Persian", "value": "fa" },
    { "label": "Polish", "value": "pl" },
    { "label": "Portuguese (Brazil)", "value": "pt" },
    { "label": "Portuguese (Portugal)", "value": "pt-pt" },
    { "label": "Punjabi", "value": "pa" },
    { "label": "Queretaro Otomi", "value": "otq" },
    { "label": "Romanian", "value": "ro" },
    { "label": "Rundi", "value": "run" },
    { "label": "Russian", "value": "ru" },
    { "label": "Samoan (Latin)", "value": "sm" },
    { "label": "Serbian (Cyrillic)", "value": "sr-Cyrl" },
    { "label": "Serbian (Latin)", "value": "sr-Latn" },
    { "label": "Sesotho", "value": "st" },
    { "label": "Sesotho sa Leboa", "value": "nso" },
    { "label": "Setswana", "value": "tn" },
    { "label": "Sindhi", "value": "sd" },
    { "label": "Sinhala", "value": "si" },
    { "label": "Slovak", "value": "sk" },
    { "label": "Slovenian", "value": "sl" },
    { "label": "Somali (Arabic)", "value": "so" },
    { "label": "Spanish", "value": "es" },
    { "label": "Swahili (Latin)", "value": "sw" },
    { "label": "Swedish", "value": "sv" },
    { "label": "Tahitian", "value": "ty" },
    { "label": "Tamil", "value": "ta" },
    { "label": "Tatar (Latin)", "value": "tt" },
    { "label": "Telugu", "value": "te" },
    { "label": "Thai", "value": "th" },
    { "label": "Tibetan", "value": "bo" },
    { "label": "Tigrinya", "value": "ti" },
    { "label": "Tongan", "value": "to" },
    { "label": "Turkish", "value": "tr" },
    { "label": "Turkmen (Latin)", "value": "tk" },
    { "label": "Ukrainian", "value": "uk" },
    { "label": "Upper Sorbian", "value": "hsb" },
    { "label": "Urdu", "value": "ur" },
    { "label": "Uyghur (Arabic)", "value": "ug" },
    { "label": "Uzbek (Latin)", "value": "uz" },
    { "label": "Vietnamese", "value": "vi" },
    { "label": "Welsh", "value": "cy" },
    { "label": "Xhosa", "value": "xh" },
    { "label": "Yoruba", "value": "yo" },
    { "label": "Yucatec Maya", "value": "yua" },
    { "label": "Zulu", "value": "zu" }
  ]

export const TranslatorLangDict = {};
TRANSLATOR_LANG_LIST.forEach(item => {
    TranslatorLangDict[item.value] = item.label;
});

export default TranslatorLangDict;
