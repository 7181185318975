import TransformerIcon from 'icons/TransformerIcon';

export default {
  context: 'ai_content_generator', // Based on context, generate GPT instruction in backend.
  title: '',
  prompt: '',
  shortDesc: '',
  prompt_widget: '', // default input
  prompt_placeholder: ``,
  prompt_style: { minHeight: '200px' },
  hide_content_length: false,
  fields: [
    {
      id: 'content-generator:custom-prompt',
      widget: 'textarea', // textarea, div
      classes: [],
      placeholder: 'Example: Write a compelling facebook post that sell',
      showPlaceholder: true,
      required: true,
      minLength: 0,
      minWords: 0,
      maxLength: 0,
      maxWords: 0,
      label: 'Prompt',
      apilabel: 'prompt',
      showLabel: true,
      shortDesc: '',
      showShortDesc: false,
      showHelp: false,
      helpText: '',
      showGuide: false,
      guideText: '',
      autocomplete: 'off', // Default off,
    }
  ],
};
