import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.css';
import ReactCodeInput from 'react-code-input';
import { Spin, Button, notification, Carousel } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import api_config from 'lib/api/common';
import UseAPI from 'lib/api/UseAPI';
import validator from 'validator';
import Logo from '../../assets/img/logo.png';
import LPPromoItem1 from './LPPromoItem1';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const SignUp = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, desc) => {
    api[type]({
      message: message,
      description: desc,
    });
  };
  const navigate = useNavigate();

  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [pwd, setPwd] = useState('');
  const [matchPwd, setMatchPwd] = useState('');
  const [isEnableSignUp, setEnableSignUp] = useState(false);
  const [isVerifyLoading, setVerifyLoading] = useState(false);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isUserLoading, setUserloading] = useState(false);

  const [user, setUser] = useState('');
  const [isDisableduser, setDisableUser] = useState(false);
  const [isVerifyStatus, setVerifyCodeStatus] = useState(false);

  const [code, setCode] = useState('');
  const [isEnablecode, setEnableCode] = useState(false);
  const [isEnableSignUpAPI, setEnableAPISignUp] = useState(false);

  const handleChange = (e) => {
    setUser(e.target.value);
  };
  const handleCode = (value) => {
    setCode(value);
  };

  const handleAPISubmit = async () => {
    setUserloading(true);
    let payload;
    if (validator.isEmail(user)) {
      payload = {
        email: user,
        signup: true,
      };
    } else {
      setUserloading(false);
      openNotificationWithIcon('error', 'Enter a valid email', 'A valid email address is required');
      return;
    }
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/send-verification-code/';
    api_config.ABORT_CONTROLLER = new AbortController();
    const signal = api_config.ABORT_CONTROLLER.signal;

    try {
      UseAPI(signal, url, 'POST', payload)
        .then(function (response) {
          if (response.code === 410) {
            openNotificationWithIcon(
              'error',
              'Failed to send verification code',
              'The provided email address already exist in our system. Please login instead. If you forgot your password, please reset. ',
            );
          }
          if (response.code === 500) {
            openNotificationWithIcon(
              'error',
              'Failed to send verification code',
              'The verification code was not sent due to some server error. Please retry again later.',
            );
          }
          if (response.code === 200) {
            openNotificationWithIcon(
              'success',
              'Verification code sent',
              'We have sent a verification code to your email. Please check your inbox ',
            );

            setVerifyCodeStatus(true);
            // Email Disabled
            setDisableUser(true);
            // Enable Phone code
            setEnableCode(true);
            setSubmitLoading(false);
            // api/verify-code/ POST
            setVerifyLoading(true);
          }

          setUserloading(false);
        })
        .catch(function (err) {
          setUserloading(false);
        });
    } catch (err) {
      openNotificationWithIcon(
        'error',
        'Failed to send verification code',
        'The verification code was not sent due to some server error. Please retry again later.',
      );
    }
  };

  const handleVerifyCode = async () => {
    setUserloading(true);
    let payload = {
      email: user,
      code: code,
    };
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/verify-code/';
    api_config.ABORT_CONTROLLER = new AbortController();
    const signal = api_config.ABORT_CONTROLLER.signal;

    try {
      UseAPI(signal, url, 'POST', payload)
        .then(function (response) {
          setUserloading(false);
          if (response.code === 400) {
            // Email Disabled
            openNotificationWithIcon(
              'error',
              'Email verification failed',
              'The security code is invalid',
            );
          }
          if (response.code === 200) {
            // Email Disabled

            setVerifyLoading(false);
            setDisableUser(true);
            // Enable Phone code
            setEnableCode(false);
            setEnableSignUp(true);
            setSubmitLoading(true);
            setEnableAPISignUp(true);
            openNotificationWithIcon(
              'success',
              'Verification successful',
              'Your email is now verified successfully ',
            );
          }
        })
        .catch(function (err) {
          openNotificationWithIcon(
            'error',
            'Email verification failed',
            'We could not verify your email. Please try again or contact support@chatscribe.pro',
          );

          setUserloading(false);
        });
    } catch (err) {
      //alert(1)
      setUserloading(false);

      openNotificationWithIcon(
        'error',
        'Email verification failed',
        'We could not verify your email. Please try again or contact support@chatscribe.pro',
      );
    }
  };

  const handleSignUp = async () => {
    setUserloading(true);
    let payload = {
      email: user,
      first_name: firstname,
      last_name: lastname,
      password: pwd,
      password2: matchPwd,
    };
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/signup/';
    api_config.ABORT_CONTROLLER = new AbortController();
    const signal = api_config.ABORT_CONTROLLER.signal;

    try {
      UseAPI(signal, url, 'POST', payload)
        .then(function (response) {
          setUserloading(false);
          if (response.success === true) {
            openNotificationWithIcon(
              'success',
              'Account created successfully',
              'Your account has been created successfully ',
            );
            navigate('/login');
          }
          if (response.code === 400) {
            openNotificationWithIcon(
              'error',
              'Signup failed',
              'Your account creation was not successful. Please try again or contact support@chatscribe.pro',
            );
          }
        })
        .catch(function (err) {
          openNotificationWithIcon(
            'error',
            'Signup failed',
            'Your account creation was not successful. Please try again or contact support@chatscribe.pro',
          );
          setUserloading(false);
        });
    } catch (err) {
      //alert(1)
      setUserloading(false);

      openNotificationWithIcon(
        'error',
        'Signup failed',
        'Your account creation was not successful. Please try again or contact support@chatscribe.pro',
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isVerifyStatus) {
      if (isEnableSignUpAPI) {
        handleSignUp();
      } else {
        handleVerifyCode();
      }
    } else {
      handleAPISubmit();
    }
  };

  return (
    <>
      <div className="flex-container">
        <div className="login-slider" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh'}}>
          <div style={{width: '100%', marginTop: 'auto', marginBottom: 'auto'}}>
          <h1
            style={{
              fontSize: 22,
              textAlign: 'center',
              marginTop: '80px',
              color: 'rgb(34, 34, 34) !important',
            }}
          >
            Welcome to <b style={{color: "#3056d3", fontWeight: '900', fontSize: '1.3rem'}}>ChatScribe Pro</b>
          </h1>
          {contextHolder}

          <div>
          <form style={{marginTop: '60px'}} className="auth-form" onSubmit={handleSubmit}>
            <input
              type="text"
              id="username"
              disabled={isDisableduser}
              placeholder="Enter your email"
              autoComplete="off"
              onChange={handleChange}
              value={user}
              required
            />
            {isEnableSignUp && (
              <div>
                <input
                  type="text"
                  id="firstname"
                  placeholder="Enter your first name"
                  autoComplete="off"
                  onChange={(e) => setfirstname(e.target.value)}
                  value={firstname}
                  required
                />
                <input
                  type="text"
                  id="lastname"
                  placeholder="Enter your last name"
                  autoComplete="off"
                  onChange={(e) => setlastname(e.target.value)}
                  value={lastname}
                  required
                />
                <input
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  autoComplete="off"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                />
                <input
                  type="password"
                  id="confirm_pwd"
                  placeholder="Enter your password again"
                  autoComplete="off"
                  onChange={(e) => setMatchPwd(e.target.value)}
                  value={matchPwd}
                  required
                />
              </div>
            )}
            {isEnablecode && (
              <ReactCodeInput
                type="text"
                className="auth-code"
                inputStyle={{
                  marginRight: '12px',
                  height: '35px',

                  padding: ' 6px 10px',
                  outline: 'none',
                  fontSize: '24px',
                  border: '1px solid #ddd',
                }}
                onChange={handleCode}
                fields={6}
              />
            )}

          <div style={{marginTop: '7px'}}>By signing up, I agree to the <a style={{textDecoration: 'underline'}} target='_blank' href="https://chatscribe.pro/privacy/">privacy policy</a> and <a style={{textDecoration: 'underline'}} target='_blank' href="https://chatscribe.pro/terms/">terms and conditions</a>.</div>

            <button className="login_btn">
              {isVerifyLoading ? (
                <div>
                  {isUserLoading && (
                    <Spin
                      indicator={antIcon}
                      style={{
                        color: 'white',
                        marginRight: '10px',
                        marginTop: '3px',
                      }}
                    />
                  )}
                  <span>Verify</span>
                </div>
              ) : isSubmitLoading ? (
                <div>
                  {isUserLoading && (
                    <Spin
                      indicator={antIcon}
                      style={{
                        color: 'white',
                        marginRight: '10px',
                        marginTop: '3px',
                      }}
                    />
                  )}
                  <span>Submit</span>
                </div>
              ) : (
                <div>
                  {isUserLoading && (
                    <Spin
                      indicator={antIcon}
                      style={{
                        color: 'white',
                        marginRight: '10px',
                        marginTop: '3px',
                      }}
                    />
                  )}
                  <span>Continue</span>
                </div>
              )}
            </button>
          </form>
          </div>

          <p style={{ textAlign: 'center', marginTop: 10 }}>
            Already have an account with us?
            <Link style={{ marginLeft: 5 }} className="create" to="/login">
              Login
            </Link>
          </p>
          </div>
        </div>
        <LPPromoItem1 />

      </div>
    </>
  );
};

export default SignUp;
