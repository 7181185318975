import React, { useEffect, useState, useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../layout/PageLayout';
import { useParams } from 'react-router-dom';
import { Tabs, Button, message, notification, Alert, Select } from 'antd';
const { TabPane } = Tabs;
import UseAPI from 'lib/api/UseAPI';
import api_config from "lib/api/common"
import "./TranscriptionDetail.css"
import TemplateForm from 'pages/preset/TemplateForm';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';


  

const AIContentGenerator = ({}) => {
    const [transStatus, setTransStatus] = useState("Pending");
    const [duration, setDuration] = useState(0);
    const [fileType, setFileType] = useState(null);
    const [file, setFile] = useState({});
    const [lang, setLang] = useState(null);
    const [topic, setTopic] = useState(null);
    const [enableProfanity, setEnableProfanity] = useState(false);
    const [transcription, setTranscription] = useState("");
    const [formattedText, setFormattedText] = useState(null);
    const [keyTakeaways, setKeyTakeaways] = useState(null);
    const [utterances, setUtterances] = useState([]);
    const [sentences, setSentences] = useState([]);
    const [refreshInProgress, setRefreshInProgress] = useState(false);
    const [transcribedFileMimeType, setTranscribedFileMimeType] = useState('application/x-mpegURL');
    const [chatId, setChatId] = useState(null);
    const [savedPrompts, setSavedPrompts] = useState([]);
    const [transcriptionUUID, setTranscriptionUUID] = useState(null);
    const [transLang, setTransLang] = useState("en");
    const [transcriptionList, setTranscriptionList] = useState([]);

    let abortController = new AbortController();

    const history = useNavigate();

    const handleGoBack = () => {
        history("/dashboard");   
    };

    const fetchTranscriptions = () => {
        let url = api_config.BASE_BACKEND_ENDPOINT + 'api/t/?fields=uuid,original_file,date_created&page_size=10000&page=1';
        let abortController = new AbortController();
        const signal = abortController.signal;
        UseAPI(signal, url, "GET", {}).then(function(data) {
          setTranscriptionList(data.results);    
          setTranscriptionUUID(data.results[0].uuid);
        }).catch(function(err) {
          setTranscriptionList([]);
        });
      }

    const fetchTranscriptionDetail = () => {
        if(refreshInProgress) {
        return false;
        }
        let langPostfix = "";
        if(transLang) {
            langPostfix = "?lang=" + transLang;
        }
        setRefreshInProgress(true);
        let url = api_config.BASE_BACKEND_ENDPOINT + 'api/t/' + transcriptionUUID + '/' + langPostfix;
        abortController = new AbortController();
        const signal = abortController.signal;
        try {
        UseAPI(signal, url, "GET", {}).then(function(data) {
            setFileType(data.file_type || "AUDIO");
            setFile(data.file_url);
            setTranscribedFileMimeType(data.tfile_mime);
            setTransStatus(data.status);
            setDuration(data.audio_duration);
            setLang(data.audio_language);
            // setSubtitleLang(data.audio_language);
            setTransLang(data.audio_language);
            setSavedPrompts(data.prompts_tab);
            // setTopic(data.audio_topic);
            // setDetectSpeaker(data.detect_speaker);
            // setEnhancedQuality(data.enhance_quality);
            // setMaskPIIData(data.redact_pii);
            setEnableProfanity(data.profanity_filter);
            // setTranscription(data.transcription);
            // setTranscriptionSummary(data.summary);
            setFormattedText(data.formatted_text);
            // setUtterances(data.utterances);
            setSentences(data.sentences);
            setKeyTakeaways(data.key_takeways);
            setRefreshInProgress(false);   
            //setTranscriptionLoaded(true);
            setChatId(data.chat_id);   
        }).catch(function(e) {
            console.error(e);
            setRefreshInProgress(false);
        });
        
        } catch (err) {
        console.error(err);
        setRefreshInProgress(false);
        }
    }

    useEffect(() => {
        if(transcriptionUUID) {
            fetchTranscriptionDetail();
        }        
    }, [transcriptionUUID]);
    
    useEffect(() => {
        fetchTranscriptions();
        // Abort fetch on component unmount
        return () => {
        if (abortController) {
            abortController.abort();
        }
        };
    }, []);

    return (
      <PageLayout title="Transcription Detail">
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '8px', paddingRight: '8px'}}>
        <h3 className='trans-detail-heading' style={{justifyContent: 'center', width: '100%'}}>
            {/* <Button type="default" onClick={handleGoBack} icon={<LeftOutlined />}> Go Back</Button> <span style={{marginLeft: '10px'}}>Create content from transcriptions</span> */}
            <Select
                style={{ marginTop: '0px', marginLeft: '20px', minWidth: '300px', maxWidth: '360px' }}
                className='lng-filter'
                placeholder="Select Transcription"
                optionFilterProp="children"
                onChange={(value) => { setTranscriptionUUID(value)}}
                value={transcriptionUUID}
                showSearch
                filterOption={(input, option) => {
                  const childrenString =
                    typeof option.children === 'object'
                      ? option.children.props.children.toString()
                      : option.children.toString();

                  return childrenString.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {transcriptionList.map((lang) => (
                  <Option key={lang.uuid} value={lang.uuid}>
                    <span>{lang.original_file}</span>
                  </Option>
                ))}
              </Select>
        </h3>
        </div>
        <div style={{height: 'calc(100vh - 60px)', overflowY: 'auto'}}>
          {
            transcriptionUUID && 
            <TemplateForm templateid={'generate-ai-content'} transcriptionId={transcriptionUUID} savedPrompts={savedPrompts} />
          }
          {
            !transcriptionUUID && refreshInProgress && 
            <LoadingOutlined />
          }
        </div>
      </PageLayout>
    );
  };
  
  export default AIContentGenerator;
  